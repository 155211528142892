import React, {useEffect} from "react";

import { useAuth } from "../contexts/auth";
import { useEventSettings } from "../contexts/eventsettings";
import { useMyRegistration } from "../contexts/myregistration.js";

import LoginPageHeader from './LoginPageHeader'; 

// import { NavLink } from 'react-router-dom';
import { LinkContainer } from "react-router-bootstrap";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';

import logo from "../assets/PITMLogo.png"; 

import './Mast.css';

function AppFrame(props){
// 	const { myRegistration, setMyRegistration } = useMyRegistration(); 
	const regContext = useMyRegistration(); 
	const setMyRegistration = regContext.setMyRegistration; 
// 	const  setMyRegistration  = useMyRegistration()[1]; 
  const { authToken, setAuthToken } = useAuth();
  const { eventSettings } = useEventSettings();
	
	if(authToken){ 
		var expiry = new Date(authToken.expiresAt); 
		var now = new Date(); 
	} 

  function logOut() {
    setAuthToken(null);
		localStorage.removeItem("authToken"); 
		localStorage.removeItem("registrationStatus");
		setMyRegistration({}); 
		window.location="/"; 
  }
	
	useEffect(() => {
		const authTokenWatcher = setInterval(() => {
			if(authToken){ 
				var expiry = new Date(authToken.expiresAt); 
				var now = new Date(); 
				if(now>expiry){ 
					setAuthToken(null);
					localStorage.removeItem("authToken"); 		
				} 
			} 
			
			
		}, 60000);
		return () => clearInterval(authTokenWatcher);
	}, [authToken,setAuthToken]);

	
  var today = new Date(); 
	var eventStart = eventSettings.startDate; 
  var Difference_In_Days = Math.ceil((eventStart.getTime() - today.getTime()) / (1000 * 3600 * 24)); 

	let headerText = (Difference_In_Days > 0) ? Difference_In_Days+" Days Until We're Together!" : 'Welcome to PITM 2024!'; 

	let framework; 
	
	if(!authToken || (authToken && (now>=expiry))){ 
		framework = <LoginPageHeader />; 
	} else { 
		framework = <header>
			<img src={logo} alt="" />
				<h2>{headerText}</h2>
				<Navbar collapseOnSelect expand="lg">
					<Navbar.Toggle>MENU</Navbar.Toggle>
					<Navbar.Collapse>
						<Nav variant="pills">
							<LinkContainer exact to="/"><Nav.Link>Dashboard</Nav.Link></LinkContainer>
							<LinkContainer to="/pack"><Nav.Link>Meet The Pack</Nav.Link></LinkContainer>
							<LinkContainer to="/profile"><Nav.Link>My Profile</Nav.Link></LinkContainer>
							<LinkContainer to="/puppypile"><Nav.Link>My Puppy Pile</Nav.Link></LinkContainer>
							<LinkContainer to="/payments"><Nav.Link>Payments</Nav.Link></LinkContainer>
							<LinkContainer to="/messages"><Nav.Link>Messages</Nav.Link></LinkContainer>
						</Nav>
					</Navbar.Collapse>
				</Navbar>
				<Button onClick={logOut} className="signoutbutton">Sign Out</Button>
			</header>;
	}
	
	return framework;
}
 
export default AppFrame;