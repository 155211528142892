import styled from 'styled-components';
import Card from 'react-bootstrap/Card';

const LoginCard = styled(Card)`
	max-width:400px; 
	margin:3em auto; 
	box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
	border-radius:10px; 
`;

const LoadingSpinner = styled.div`
	width:200px; 
	text-align:center; 
	margin:0 auto; 
`; 

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const LoginInput = styled.input`
  padding: 1rem;
  border: 1px solid #999;
  margin-bottom: 1rem;
  font-size: 0.8rem;
width:100%; 
	border-radius:10px; 
outline: none;
transition: all 0.3s cubic-bezier(.25,.8,.25,1);
&:focus {
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`;

const LoginButton = styled.input.attrs({ 
  type: 'submit',
  value: 'Sign In'
})`
	background-color:#692C90; 
  border:0px; 
	border-radius: 10px;
	padding: .75rem;
  color: white;
  font-weight: 700;
  width: 100%;
  margin-bottom: 1rem;
  font-size: 1.3rem;
outline: none;
transition: all 0.3s cubic-bezier(.25,.8,.25,1);
&:hover {
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
`;

const StartReset = styled(LoginButton).attrs({
  value: 'Send me a Code'
})`
`;

const FinishReset = styled(LoginButton).attrs({
  value: 'Reset my Password'
})`
`;

export { LoginCard, LoginForm, LoginInput, LoginButton, LoadingSpinner,StartReset,FinishReset };