import React, { useState }  from "react";

import {
  useRouteMatch,
	useHistory
} from "react-router-dom";

import PublicInfoEditor from './PublicProfile.js'
import PrivateInfoEditor from './PrivateDetails.js'
import TravelInfo from './TravelInfo.js'

import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container'
import Tab from 'react-bootstrap/Tab'

import './Profile.css'; 

function Profile(props) {
  let history = useHistory();

  let match = useRouteMatch();
	const [tabKey, setTabKey] = useState((match.path==='/profile/private')?'private':((match.path==='/profile/travel')?'travel':'public'));
																	
	return (<>
						<Container>
							<h1>My Profile</h1>
							<Tabs id="profile-tab-interface"
										activeKey={tabKey}
										onSelect={(k) => { if(k==='travel') history.push('/profile/travel'); 
																			 if(k==='private') history.push('/profile/private'); 
																			 if(k==='public') history.push('/profile'); 
																			 setTabKey(k); }}>
								<Tab eventKey="public" title="Public Profile" >
									<PublicInfoEditor />
								</Tab>
								<Tab eventKey="private" title="Personal Details"  >
									<PrivateInfoEditor />
								</Tab>
								<Tab eventKey="travel" title="Travel Info" >
									<TravelInfo />
								</Tab>
							</Tabs>
						</Container>
					</>
	);
}
 
export default Profile;