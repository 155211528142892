import React from "react";

import { FormCard } from "../styled-components/Forms.js";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function DataUsage(props) {
	
  return (<Container>
						<h1>Personal Data Usage, Privacy, &amp; Security</h1>
						<Row>
							<Col>
									<FormCard id="" className="blue">
										<Card.Header as="h5">User Role Types</Card.Header>
										<Card.Body>
											<p>The My Experience application uses role types to control access to user information.</p>
											<p><b>Admins</b> are the event directors (PITM Board of Directors), and have the most access to guest information in order to plan and operate a safe and enjoyable experience.</p>
											<p><b>Guests</b> are the people attending the event, and only have access to their own information and limited profile information of other guests, to facilitate getting to know each other before the event.</p>
											<p><b>Volunteers</b> are event staff who have access to the same information as Guests and any relevant private information needed to perform their role (for example, travel information).</p>
										</Card.Body> 
									</FormCard>
									<FormCard id="" className="blue">
										<Card.Header as="h5">How We Use Your Information</Card.Header>
										<Card.Body>
											<p>Data privacy is very important to us. Here is the information we ask for and how we use it while planning and operating PITM. These
											terms and guidelines are subject to change without notice at any time.</p>
											
											<p><b>Public Profile Information</b></p>
											<table className="terms">
												<tr>
													<td>Scene Name</td>
													<td>How we refer to you before and during the event. Displayed on your My Experience profile and event badge. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>Role Identity</td>
													<td>Displayed on your My Experience profile and indicated on your badge with an icon. Aggregated for event metrics and planning. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>Pronouns</td>
													<td>Displayed on your My Experience profile and event badge. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>Hometown</td>
													<td>Optionally displayed on your My Experience profile (you can turn this off). Aggregated for event metrics. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>Website</td>
													<td>Displayed on your My Experience profile. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>My Bio</td>
													<td>Displayed on your My Experience profile. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>Photos</td>
													<td>Printed on your My Experience profile. Visible to all user roles.</td>
												</tr>
												<tr>
													<td>My Social Media Accounts</td>
													<td>Printed on your My Experience profile with links to the relevant website. PITM may also follow you from our social media accounts. Visible to all user roles.</td>
												</tr>
											</table>
												
											<p><b>Personal Details</b></p>
											<table className="terms">
												<tr>
													<td>Legal Name</td>
													<td>Used to verify your identity at check-in. Given to emergency personnel if necessary. Only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins.</td>
												</tr>
												<tr>
													<td>Birthdate</td>
													<td>Used to verify your identity and age at check-in. Given to emergency personnel if necessary. Only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins. You may choose to display or hide your age on your My Experience profile.</td>
												</tr>
												<tr>
													<td>Email Address</td>
													<td>Used to communicate before, during, and after the event. Only visible to Admins.</td>
												</tr>
												<tr>
													<td>Mobile Phone Number</td>
													<td>Used to communicate before, during, and after the event. Only visible to Admins. You may choose to turn off text messages for regular notifications, though in certain circumstances Admins may override.</td>
												</tr>
												<tr>
													<td>Gender Identity</td>
													<td>Referenced for housing assignments if necessary. Aggregated for event metrics. Only visible to Admins. </td>
												</tr>
												<tr>
													<td>Emergency Contact</td>
													<td>Used when you are unconscious or have a serious medical issue. Given to emergency personnel if necessary. Only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins.</td>
												</tr>
												<tr>
													<td>T-Shirt Size</td>
													<td>Used to order you a properly sized event T-Shirt! Only visible to Check-In Volunteers (and only during check-in) and Admins.</td>
												</tr>
												<tr>
													<td>Dietary Restrictions and Food Allergies</td>
													<td>Provided to the Venue Kitchen staff in order to plan meals that you can eat. We do not share your name with this information. Otherwise, only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins.</td>
												</tr>
												<tr>
													<td>Mobility Accommodations</td>
													<td>Used by admins to ensure your housing is suitable for your needs. Otherwise, only visible to Check-In Volunteers (and only during check-in) and Admins.</td>
												</tr>
												<tr>
													<td>Medical Allergies & Emergency Care Info</td>
													<td>Given to emergency personnel if necessary. Only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins. </td>
												</tr>
												<tr>
													<td>Other Care Notes</td>
													<td>Used to plan a safe and enjoyable event for you whatever your needs may be. Only visible to Check-In Volunteers (and only during check-in), Medic Volunteers, and Admins. </td>
												</tr>
											</table>
											
											<p><b>Registration Status &amp; Payments</b></p>
											<table className="terms">
												<tr>
													<td>Registration Status</td>
													<td>Identifies whether your registration fee is fully paid, partially paid, or if your registration is cancelled. Only visible to Check-In Volunteers if registration is not fully paid (and only during check-in) and to Admins. 
															Your profile will not be visible if you have not made a payment or if you have cancelled your registration.</td>
												</tr>
												<tr>
													<td>Total Amount Paid & Total Amount Due</td>
													<td>Details your total registration fee and any amounts paid. Only visible to Check-In Volunteers if registration is not fully paid (and only during check-in) and Admins.</td>
												</tr>
												<tr>
													<td>Payment History</td>
													<td>Details all payments you've made to PITM and any administrative credits applied. Only visible to Admins.</td>
												</tr>
											</table>
												
											<p><b>Travel Info</b></p>
											<table className="terms">
												<tr>
													<td>Arrival Plans & Flight Information</td>
													<td>Used to plan our volunteer staffing, check-in hours, late check-ins. Used to track flights in case it's late and adjust shuttle schedules if needed. Only visible to Travel Coordinators (Volunteers) and Admins.</td>
												</tr>
												<tr>
													<td>Departure Plans</td>
													<td>Used to plan our volunteer and return airport shuttle schedules. Only visible to Travel Coordinators (Volunteers) and Admins.</td>
												</tr>
											</table>
											<p><b>Credit Cards</b></p>
											<p>We use a secure, third-party credit card processor that provides an opaque transaction ID, payment processing status, and the last four digits of your credit card number. Our applications do not access or save your credit card details.</p>

											<p><b>Housing & Puppy Piles</b></p>
											<table className="terms">
												<tr>
													<td>Housing Type Preference</td>
													<td>Used to plan housing assignments before the event. Visible to other Puppy Pile members and Admins.</td>
												</tr>
												<tr>
													<td>Linen Rentals</td>
													<td>Used to plan housing needs and supplies before the event. Visible to other members of your Puppy Pile, Check-In Volunteers (and only during check-in), Housing Volunteers, and Admins.</td>
												</tr>
												<tr>
													<td>Housing Assignments</td>
													<td>Used to find you during the event, identifying property damage, returning lost articles, and maintenance needs all only if necessary. Only visible to Check-In Volunteers (and only during check-in), Housing Volunteers and Admins.</td>
												</tr>
												<tr>
													<td>Puppy Pile Preferences</td>
													<td>Used to plan housing assignments before the event. Visible to other members of the Puppy Pile and Admins.</td>
												</tr>
												<tr>
													<td>Puppy Pile Membership</td>
													<td>Used to plan housing assignments before the event. Visible to other members of the Puppy Pile, anyone invited to join the Puppy Pile, and Admins.</td>
												</tr>
												<tr>
													<td>Puppy Pile Messages</td>
													<td>Used to let your Puppy Pile plan travel, packing lists, and activities together. Visible to other members of the Puppy Pile. Visible to Technology Admins in special circumstances (e.g., system debugging, investigating harrassment)</td>
												</tr>
											</table>
											
											<p><b>Messages</b></p>
											<table className="terms">
												<tr>
													<td>Messages Between PITM Directors and You</td>
													<td>Visible to the members of the communication thread (you, PITM directors). Admins can also see "envelope" information including read-receipts or notification dismissals.</td>
												</tr>
												<tr>
													<td>Messages Between You and Other Individuals (Guests, Volunteers)</td>
													<td>Visible to the members of the communication thread. Admins can see "envelope" information (to/from, dates, message status). Visible to Technology Admins in special circumstances (e.g., system debugging, investigating harrassment)</td>
												</tr>
												<tr>
													<td>Announcements and Reminders</td>
													<td>Various announcements, reminders, or follow-up messages may be sent by Admins. We may use the data from other sections to select recipients (e.g., guests who haven't fully paid, guests who haven't provided travel information, etc). 
														Admins can see "envelope" information including read-receipts and notification dismissals.</td>
												</tr>
												<tr>
													<td>Messages Sent By Email</td>
													<td>The My Experience application will send messages via email in addition to making them visible to you in the application. 
														These emails may contain a read-receipt mechanism that identifies when you open the message. You may be able to reply to the email to reply to the message in My Experience. 
														We use an automated third party mail service to send and receive these automated emails. Third party services's automated services will see your email address and message content; they have their own privacy policies. </td>
												</tr>
											</table>
											
											<p><b>Sponsors</b></p>
											<p>Puppies In The Mountains depends on sponsors to subsidize the event and our organizational operations. In return, we provide services to these sponsors including but not limited 
												to advertising space in our event program, display advertising on our website and My Experience application, and promotional emails to our guests and community. We do not provide
												any personally identifiable information of our guests to our sponsors. Any advertisements are placed in the system by PITM Admins and are not demographically targeted. Any promotional 
												emails are sent by PITM on behalf of sponsors.</p> 
											
										</Card.Body> 
									</FormCard>
								
									<FormCard id="" className="blue">
										<Card.Header as="h5">Data Security</Card.Header>
										<Card.Body>
											<p>The PITM Website and My Experience application is hosted on a secure cloud computing platform. All data is encrypted at rest inside these sytems, and our database is 
												not accessible via the Internet except by explicitly designated IP addresses. All data communication takes place over Transport Layer Security and is thus encrypted between our 
												backend services and frontend applications, and between our frontend applications and your devices.</p>
										</Card.Body> 
									</FormCard>
									<FormCard id="" className="blue">
										<Card.Header as="h5">Membership/Guest Agreement</Card.Header>
										<Card.Body>
											<p>Your participation in events operated by Puppies In The Mountains, Inc., is subject to and governed by the Membership Agreement, to which you agreed during registration and 
												will sign during check-in at the event. Parts of that agreement that are relevant to data usage, privacy, and security are:</p>
											<p><i>Privacy and Respect for All Members</i><br />
											All are welcome at Puppies in the Mountains regardless of age, racial identity, gender identity, physical capability or disability, sexual orientation, 
												community identity. You agree to respect all members, event coordinators, and volunteers.</p> 
											<p>Members may share personal details and experience before, during, or after the event. You must respect the privacy of all members, and agree not to 
												share any personal information you learn about any other member including their legal name, home town, sexual preferences, community identity, personal 
												experiences or stories, or actions taken during the event.</p>
											<p><i>Cameras &amp; Photography</i><br />
											The privacy of members is paramount to PITM. Only the authorized photographer(s) may make photographic, video, or audio recordings in ANY public space including, 
												but not limited to, the meeting rooms, mosh area, dining hall, outdoor areas, or cabin common areas. Private photos taken in individual guest rooms are permitted 
												only with the permission of everyone else in the photo. Any member caught using a camera (including cellphone cameras) in restricted areas may have their membership 
												immediately cancelled, may be asked to leave the event, and the equipment confiscated and checked to ensure that no photos or recordings were taken. They may also 
												be banned from future membership. </p>
											<p>Members may have their photo taken by authorized photographers. Authorized photographers will have clear and obvious credentials on their volunteer T-Shirt or badge. 
												Any recordings made by the authorized photographers will be private to guests of PITM 2020 only. Photos used for PITM media or advertising will be scheduled 
												photoshoots apart from the main event, and spearate releases are required.</p>
											<p><i>Release of Liability</i><br/>
											In consideration of their services, I hereby voluntarily release, forever discharge, and agree to indemnify and hold harmless Puppies in the Mountains, Inc., 
												the venue, their owners, agents, officers, volunteers, boards of directors, staff, and all other persons acting in any capacity on their behalf 
												(hereinafter collectively referred to as “PITM/Venue”), on behalf of myself, my children, my parents, my heirs, assigns, personal representative and estate, 
												from any and all claims, demands, or causes of action, which are in any way connected with my voluntary participation in any activity at the event, or my use 
												of any of the equipment provided by PITM/Venue.</p>
											<p>(Paragraph 8) I understand that photography (including, but not limited to, digital images captured by cell phones, cameras or other devices), videography, 
												audio recordings or any other audio or visual recording of any kind, is not permitted in any part of the event without the express approval of the event owners. 
												The event includes the venue's entire grounds (including wooded areas) as well as off-property excursions organized by PITM.</p>
											<p>(Paragraph 9) I understand that while PITM takes effort to protect the data which it collects that data breaches may occur due to malicious penetration 
												of its computer systems, servers, or third party vendors. I release all liability and hold PITM harmless for any known or unknown theft of data from its 
												systems or the systems of its third party vendors, for any reason.</p>
											<p>(Paragraph 10) I am not acting in the capacity of, as a member of, or under the direction of, any law enforcement or postal agency or governmental agency 
												of any type, whether federal, state or local in nature. I am not attending the event or any of the activities at the event for the purpose of entrapment or 
												to gather information and/or testimony that would lead to (or further the arrest or prosecution of) the owners and organizers of the event, the owners of 
												the premises, or any individual attending the event. I am not attending the event or any of the activities at the event for the purpose of attempting to 
												influence the host hotel to not do business with PITM/Venue, to harass or discourage any person from attending the event or for the purpose of causing harm 
												of any kind to the event and/or its attendees. Furthermore, I am not acting in the capacity of, as a member of, or under the direction of, any media agency, 
												and I will not publish or submit for any kind of publication information, writings, photographs, audio recordings, video recordings, or any other records of 
												activities regarding the event or activities at the event, without the express approval of the owners of the event.</p>
										</Card.Body> 
									</FormCard>
								
								</Col>
							</Row>
						</Container>
					);
}
 
export default DataUsage;