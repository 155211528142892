import React, {useState,useEffect,useRef} from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import { useMyRegistration } from "../../contexts/myregistration.js";
import styled from 'styled-components';
import { Typeahead, Token, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import {
  Link
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'; 
import { faInfoCircle } from '@fortawesome/pro-regular-svg-icons'; 


import { LoadingSpinner } from "../../styled-components/AuthForms.js";
import { FormLabel, RegularButton } from "../../styled-components/Forms.js";
import { BreadcrumbLink, ButtonLink, TextLink } from "../../styled-components/SmallElements.js";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Spinner from 'react-bootstrap/Spinner'
import Button from 'react-bootstrap/Button'
// import Alert from 'react-bootstrap/Alert'
// import Overlay from 'react-bootstrap/Overlay'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Dropdown from 'react-bootstrap/Dropdown'
import DropdownButton from 'react-bootstrap/DropdownButton'


import Checkbox from '../../custom-widgets/Checkbox.js'; 
import PuppyPileChat from './PuppyPileChat.js'; 


library.add(
	faArrowLeft,
	faInfoCircle
);


const InviteLine = styled.div`
	width:100%; 
	border:1px solid #EEEEEE; 
	border-radius:10px; 
	position:relative; 
	margin-right:0px; 
	margin-left:0px; 
	margin-bottom:10px; 
	padding:10px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	& p{ 
		margin-bottom:0px; 
	} 
	&.declined{ 
		box-shadow: 0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
	}
	&.declined p{ 
		color:#999; 
	} 
	&.declined p b{ 
		font-weight:normal; 
	}
	&.declined p b::after{ 
		content: " (Declined)";
	}

`; 
const DeclinableTextLink = styled(TextLink)`
.declined &{ 
		color:#94a0d6;
	} 
`; 
const PileMembers = styled(Row)`
	position:relative; 
 	opacity:0; 
 	display:none; 
	transition: opacity 1s;
	&.visible{ 
		display:block; 
		opacity:1; 
}
`; 
const AcceptButton = styled(RegularButton)`
	padding: 5px 10px;
	background-color:#0d9348; 
	margin:0px 3px 5px; 
	&:hover{ 
		background-color:#0d9348; 
	}
`; 
const DeclineButton = styled(RegularButton)`
	padding: 5px 10px;
	border:1px solid #b82a34; 
	color:#b82a34;
	background-color:transparent; 
	margin:0px 3px 5px; 
	&:hover{ 
		color:#FFFFFF; 
		background-color:#b82a34; 
		border-color:#b82a34;
	} 
	.declined &{ 
		visibility:hidden;
	}
`; 
const CreateButton = styled(RegularButton)`
	background-color:#0d9348; 
	width:100%; 
	padding: 6px 20px 7px;
	margin:0px auto; 
	&:hover{ 
		background-color:#0d9348; 
	}
`; 

const ProfilePic = styled.div.attrs({ 
  className:'profilephoto'
})`
	width:60px; 
	border:1px solid #DEDEDE; 
	height:60px;
	margin:5px; 
	display:inline-block; 
	z-index:10;
  background: url(${props => props.image}) center center no-repeat !important; 
	background-size: cover !important;
&:hover{ 
	box-shadow:inset 0px 0px 0px 2px #f0c945;
}
&:first-of-type{ 
	margin-left:0px; 
} 
`; 



function PuppyPile(props) {
	const { myRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
	const [ puppyPiles, setPuppyPiles ] = useState([]); 
	const [loadingPuppyPileInfo, setLoadingPuppyPileInfo] = useState(true); 
	const [creatingPuppyPile, setCreatingPuppyPile] = useState(false); 
	const [isSavingPrefs, setIsSavingPrefs] = useState(false); 
	const [isSavingMembers, setIsSavingMembers] = useState(false); 
	const [isChangingAlpha, setIsChangingAlpha] = useState(false); 
	const [isCreatingPile, setIsCreatingPile] = useState(false); 
	const [pileAcceptDeclineButtonSpinners,setPileAcceptDeclineButtonSpinners] = useState({}); 

	const [myPuppyPile,setMyPuppyPile] = useState(null); 
	const [myPuppyPileIsCreator,setMyPuppyPileIsCreator] = useState(false); 
	const [mayEditPile,setMayEditPile] = useState(false); 
	const [mayAddMembers,setMayAddMembers] = useState(false); 
	const [newPileName,setNewPileName] = useState(false); 
	const [autoCompleteList,setAutoCompleteList] = useState([]); 
// 	const [autoCompleteValue,setAutoCompleteValue] = useState(''); 
	const [membersSelectedForNewPuppyPile,setMembersSelectedForNewPuppyPile] = useState(''); 

	const [doEditPuppyPilePrefs,setDoEditPuppyPilePrefs] = useState(false); 
	const [showPuppyPileOverview,setShowPuppyPileOverview] = useState(false); 
	const [showDeletePuppyPileConfirmation,setShowDeletePuppyPileConfirmation] = useState(false); 
	const [showRemoveMemberConfirmation,setShowRemoveMemberConfirmation] = useState(false); 
	const [showMakeAlphaConfirmation,setShowMakeAlphaConfirmation] = useState(false); 
	
	const [holdRemoveMemberId,setHoldRemoveMemberId] = useState(null); 
	const [holdMakeAlphaId,setHoldMakeAlphaId] = useState(null); 
	const [holdMembersPreChanges,setHoldMembersPreChanges] = useState(null); 
	
	const [editPileName,setEditPileName] = useState(''); 
	const [editPileHousingType,setEditPileHousingType] = useState(''); 
	const [editPileRequests,setEditPileRequests] = useState(''); 
	const editPuppyPileAddMemberRef = useRef();
	const inviteMembersToPPRef = useRef();
	
	const [doEditPuppyMembers,setDoEditPuppyMembers] = useState(false); 
	const [doInviteNewMembers,setDoInviteNewMembers] = useState(false); 
	const [membersSelectedToInviteToPuppyPile,setMembersSelectedToInviteToPuppyPile] = useState(null); 
	const [editMemberPermissions, setEditMemberPermissions] = useState(new Map());
  const updatEditMemberPermissions = (k,v) => {
    setEditMemberPermissions(editMemberPermissions.set(k,v));
  }
	
	const [doSwitchPuppyPiles,setDoSwitchPuppyPiles] = useState(false); 
		
	let defaultProfilePics = {
				pup:"/profile_generic_pup.png",
				dog:"/profile_generic_pup.png",
				cat:"/profile_generic_cat.png",
				pony:"/profile_generic_pony.png",
				'other-critter':"/profile_generic_other.png",
				handler:"/profile_generic_human.png",
				parent:"/profile_generic_human.png",
				trainer:"/profile_generic_human.png",
				friend:"/profile_generic_human.png",
				other:"/profile_generic_human.png"
	}
	
	function startCreatePuppyPile(){ 
		setCreatingPuppyPile(true); 
		var name = myRegistration.guest.sceneName; 
		var suffix = (name.substr(name.length - 1)==='s')?"'":"'s"; 
		setNewPileName(name+suffix+' Puppy Pile'); 
	} 
	
	
	function setMyPuppyPileStateVars(pile){ 
		var isCreator = false; 
		for(var i in pile.members){ 
			if(pile.members[i].registrationId===myRegistration.id){ 
				if(pile.members[i].status==='creator') isCreator = true; 
				setMayEditPile(pile.members[i].mayEditPile); 
				setMayAddMembers(pile.members[i].mayAddMembers); 
			}
			editMemberPermissions.set(pile.members[i].registrationId+'_editpile',pile.members[i].mayEditPile); 
			editMemberPermissions.set(pile.members[i].registrationId+'_addmembers',pile.members[i].mayAddMembers); 
		} 
		setMyPuppyPile(pile); 
		setMyPuppyPileIsCreator(isCreator); 
		setEditPileName(pile.name); 
		setEditPileHousingType(pile.housingTypePreference); 
		setEditPileRequests(pile.housingRequests); 
		setEditMemberPermissions(editMemberPermissions); 		
	} 
	
	function goCreatePuppyPile(){ 
		setIsCreatingPile(true); 
		var dataToSend = {name:newPileName,housing_type_preference:null,housing_preferences:null,members:[]}; 
		dataToSend.members.push({registrationId:myRegistration.id,status:'creator',may_edit_pile:true,may_add_users:true}); 
		for(var i in membersSelectedForNewPuppyPile){ 
			dataToSend.members.push({registrationId:membersSelectedForNewPuppyPile[i].registrationId,status:'invited',may_edit_pile:false,may_add_users:false}); 
		} 

		axios.post(process.env.REACT_APP_API_HOST+"/housing/puppypiles",dataToSend,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setCreatingPuppyPile(false); 
										setMyPuppyPileStateVars(result.data); 
										setNewPileName(''); 
										setMembersSelectedForNewPuppyPile(''); 
										setDoSwitchPuppyPiles(false); 
										setIsCreatingPile(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	// load initial - my puppy piles and registrations typeahead options
	useEffect(()=>{ 

		axios.get(process.env.REACT_APP_API_HOST+"/housing/puppypiles/mine",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setPuppyPiles(result.data.puppyPiles); 
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
										console.log("err"); 
									}
								}).catch(e => {
									console.log(e); 
										setPuppyPiles([]); 
										setLoadingPuppyPileInfo(false); 
								});	
				
		axios.get(process.env.REACT_APP_API_HOST+"/registrations/search?fields=guest.sceneName,guest.role,profile,profile.photos.primary",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										var res = []; 
										var thisobj; 
										for(var i in result.data.results){ 
											if(result.data.results[i].id !== myRegistration.id){ 
												thisobj = {}; 
												thisobj.registrationId = result.data.results[i].id; 
												thisobj.name = result.data.results[i].guest.sceneName; 
												thisobj.role = result.data.results[i].guest.role;
												thisobj.profilePic = (result.data.results[i].profile.photos && result.data.results[i].profile.photos.length>0) ? 
																	 result.data.results[i].profile.photos[0].uri//+'?size=small&bearer='+authToken.token
																	 :
																	 null; 
												res.push(thisobj); 
											}
										} 
										setAutoCompleteList(res); 
									} else {
									}
								}).catch(e => {
								});	
	
	// eslint-disable-next-line
	},[]); 
	
	
	//process puppyPile List Changes
	//populate myPuppyPile if in one.
	useEffect(()=>{ 
		if(Array.isArray(puppyPiles)){
			var acceptButtons = {}; 
// 			var declineButtons = {}; 
			for(var i in puppyPiles){ 
				acceptButtons[puppyPiles[i].id] = false; 
				for(var m in puppyPiles[i].members){ 
					if(puppyPiles[i].members[m].registrationId === myRegistration.id){ 
						if(puppyPiles[i].members[m].status==='accepted' || puppyPiles[i].members[m].status==='creator'){ 
							setMyPuppyPileStateVars(puppyPiles[i]); 
						}
					}
				}
				setPileAcceptDeclineButtonSpinners(acceptButtons); 
				setLoadingPuppyPileInfo(false); 
			} 
		}
		
	// eslint-disable-next-line
	},[puppyPiles]); 
	
	function showPileMembers(link,aRef){ 
		aRef.current.className="visible"; 
		link.style.display = "none"; 
	} 

	function declinePile(pileId,registrationId){ 
		var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
		cloneAcceptSpinners[pileId] = true; 
		setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
		
		if(myPuppyPile && myPuppyPile.id === pileId){ 
			setMayEditPile(false); 
			setMayAddMembers(false); 
			setMyPuppyPile(null); 
			setMyPuppyPileIsCreator(false); 
			setEditPileName(''); 
			setEditPileHousingType(null); 
			setEditPileRequests(''); 
			setEditMemberPermissions(new Map()); 		
			setLoadingPuppyPileInfo(false); 
			setDoSwitchPuppyPiles(false); 
		} 
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+pileId+"/members/"+registrationId,{status:'declined'},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {

										var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
										cloneAcceptSpinners[pileId] = false; 
										setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
										
										for(var i=0;i<puppyPiles.length;i++){ 
											if(puppyPiles[i].id===pileId){ 
												for(var m=0;m<puppyPiles[i].members.length;m++){ 
													if(puppyPiles[i].members[m].registrationId===registrationId){ 
														puppyPiles[i].members[m].status='declined'; 
													}
												} 
											} 
										} 
										
										
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	function acceptPile(pileId,registrationId){ 
		var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
		cloneAcceptSpinners[pileId] = true; 
		setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+pileId+"/members/"+registrationId,{status:'accepted'},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
										cloneAcceptSpinners[pileId] = false; 
										setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
										for(var i=0;i<puppyPiles.length;i++){ 
											if(puppyPiles[i].id===pileId){ 
												for(var m=0;m<puppyPiles[i].members.length;m++){ 
													if(puppyPiles[i].members[m].registrationId===registrationId){ 
														puppyPiles[i].members[m].status='accepted'; 
														setMyPuppyPileStateVars(puppyPiles[i]); 
														setDoSwitchPuppyPiles(false); 
													}
												} 
											} 
										} 
										
										
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	function confirmPileDelete(){ 
		axios.delete(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setPuppyPiles(result.data.puppyPiles); 
										setMayEditPile(false); 
										setMayAddMembers(false); 
										setMyPuppyPile(null); 
										setMyPuppyPileIsCreator(false); 
										setEditPileName(''); 
										setEditPileHousingType(null); 
										setEditPileRequests(''); 
										setEditMemberPermissions(new Map()); 		
										setLoadingPuppyPileInfo(false); 
										
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	function handleEditMemberToggle(which,who){ 
		for(var i in myPuppyPile.members){ 
			if(myPuppyPile.members[i].registrationId===who){ 
				if(which==='editpile'){ 
					myPuppyPile.members[i].mayEditPile = !myPuppyPile.members[i].mayEditPile; 
					updatEditMemberPermissions(who+'_editpile',!editMemberPermissions.get(who+'_editpile')); 
				} else if(which==='addmembers') { 
					myPuppyPile.members[i].mayAddMembers = !myPuppyPile.members[i].mayAddMembers; 
					updatEditMemberPermissions(who+'_addmembers',!editMemberPermissions.get(who+'_addmembers')); 
				}
			}
		} 
		setMyPuppyPile(myPuppyPile); 
	} 
	
	function startEditPileMembers(){ 
		setHoldMembersPreChanges(myPuppyPile.members); 
		setDoEditPuppyMembers(true);
	} 
	function cancelEditPileMembers(){ 
		var clonePile = {...myPuppyPile}; 
		clonePile.members = holdMembersPreChanges; 
		setMyPuppyPile(clonePile); 
		setDoEditPuppyMembers(false);
		
	} 
	function savePuppyPilePreferences(){ 
		setIsSavingPrefs(true); 
		myPuppyPile.housingTypePreference = editPileHousingType; 
		myPuppyPile.housingRequests = editPileRequests; 
		myPuppyPile.name = editPileName; 
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id,myPuppyPile,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setMyPuppyPileStateVars(result.data); 
										setDoEditPuppyPilePrefs(false); 
										setIsSavingPrefs(false); 
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	function savePuppyPileMemberChanges(){ 
		setIsSavingMembers(true); 
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id+'/members',{members:myPuppyPile.members},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setMyPuppyPileStateVars(result.data); 
										setDoEditPuppyMembers(false); 
										setShowMakeAlphaConfirmation(false); 
										setShowRemoveMemberConfirmation(false); 
										setIsSavingMembers(false); 
										setIsChangingAlpha(false); 
										if(!result.data.puppyPiles || result.data.puppyPiles.length<1) setLoadingPuppyPileInfo(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	function confirmRemoveMember(){ 
		var newMembers = []; 
		for(var i in myPuppyPile.members){ 
			if(myPuppyPile.members[i].registrationId!==holdRemoveMemberId) newMembers.push(myPuppyPile.members[i]); 
		}
		myPuppyPile.members = newMembers; 
		setMyPuppyPile(myPuppyPile); 
		setShowRemoveMemberConfirmation(false); 
	} 
	function confirmMakeAlpha(){ 
		setIsChangingAlpha(true); 
		for(var i in myPuppyPile.members){ 
			if(myPuppyPile.members[i].registrationId===holdMakeAlphaId) myPuppyPile.members[i].status = 'creator'; 
			if(myPuppyPile.members[i].registrationId===myRegistration.id) myPuppyPile.members[i].status = 'accepted'; 
		}
		savePuppyPileMemberChanges(); 
	} 
	
	function onSelectAddMemberToPile(member){ 
		for(var i in myPuppyPile.members){ 
			if(member[0].registrationId === myPuppyPile.members[i].registrationId){ 
				editPuppyPileAddMemberRef.current.clear();
				return false; 
			} 
		} 
		
		
		var cloneMembers = [...myPuppyPile.members]; 
		var clonePile = {...myPuppyPile}; 
		var newmember = { registrationId:member[0].registrationId,
											name:member[0].name,
											profilePic:member[0].profilePic,
										  mayAddMembers:false,
										  mayEditPile:false,
										  status:'invited',
										  unsaved:true,
										  role:member[0].role }; 
		cloneMembers.push(newmember); 
		clonePile.members = cloneMembers; 
		setMyPuppyPileStateVars(clonePile); 
		editPuppyPileAddMemberRef.current.clear();
	} 

	function cancelInvitationWorkflow(){ 
		setDoInviteNewMembers(false); 
		inviteMembersToPPRef.current.clear();
	} 
	
	function goInviteAdditionalMembers(){ 
		setIsSavingMembers(true); 
		var cloneMembers = [...myPuppyPile.members]; 
		
		var alreadyInPile = false; 
		for(var i in membersSelectedToInviteToPuppyPile){ 
			alreadyInPile = false; 
			for(var d in cloneMembers){ 
				if(cloneMembers[d].registrationId === membersSelectedToInviteToPuppyPile[i].registrationId) alreadyInPile = true; 
			}
			if(!alreadyInPile) cloneMembers.push({registrationId:membersSelectedToInviteToPuppyPile[i].registrationId,status:'invited',may_edit_pile:false,may_add_users:false}); 
		} 
		
		inviteMembersToPPRef.current.clear();

		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id+'/members',{members:cloneMembers},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										setMyPuppyPileStateVars(result.data); 
										setDoInviteNewMembers(false); 
										setIsSavingMembers(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
//   return (<Container>
// 		<p>A Puppy Pile is a group of guests that want to stay in the same cabin (up to 2 rooms) or yurt together. If your pile requests a cabin, you'll likely share the cabin with another puppy pile. If you want a space all to yourself, you can request a yurt.</p>
// 		<p>The Puppy Piles system will open this Spring.</p>
//   </Container>); 

  return (<Container>
			{loadingPuppyPileInfo && <LoadingSpinner><Spinner animation="grow" variant="success" /></LoadingSpinner> }
			{!loadingPuppyPileInfo && !puppyPiles && !creatingPuppyPile && !myPuppyPile && <Row>
												<Col>
												<p>A Puppy Pile is a group of up to 8 guests that want to stay in the same cabin or yurt together. As most cabin rooms max out at 4 people, your Puppy Pile may be split across multiple rooms but will be in the same cabin or yurt together. </p>												</Col>
												<Col>
														<p>To join an existing Puppy Pile, ask your friends to invite you and the invitation will appear here. Or create your own Puppy Pile and invite others to join you!</p>
														<RegularButton className="full-width" onClick={()=>{ startCreatePuppyPile(); }}>Create a Puppy Pile</RegularButton>
														
												</Col>
											</Row> } 
			
			{!loadingPuppyPileInfo && puppyPiles && !myPuppyPile && !creatingPuppyPile && !doSwitchPuppyPiles && <Row>
												<Col>
													<p>A Puppy Pile is a group of up to 8 guests that want to stay in the same cabin or yurt together. As most cabin rooms max out at 4 people, your Puppy Pile may be split across multiple rooms but will be in the same cabin or yurt together. </p>
												</Col>
												<Col>
													<p>{puppyPiles.length>0 && <>You've been invited to { (puppyPiles.length>1) ? 'these Puppy Piles! Choose one to join.':'a Puppy Pile!'}</>}</p>
													{puppyPiles.map((pile) => { var thisRef = React.createRef();
																										 var myStatusInPile = ''; 
																										 for(var i in pile.members){ 
																											 if(pile.members[i].registrationId === myRegistration.id){ 
																												 if(pile.members[i].status==='accepted' || pile.members[i].status==='declined') myStatusInPile = pile.members[i].status;  
																											 } 
																										 } 
																										 return (
																<InviteLine key={pile.id} className={ "puppyPileInvitationLine " + myStatusInPile }>
				 													<Row>
																		<Col sm={7}>
																			<p><b>{pile.name}</b><br /><DeclinableTextLink onClick={(e)=>showPileMembers(e.target,thisRef)}>See Who's In This Pile</DeclinableTextLink></p>
																		</Col>
																		<Col sm={5}>
																			{pileAcceptDeclineButtonSpinners[pile.id] ? 
																				<Spinner animation="grow" variant="success" />
																				:
																				<>
																					<AcceptButton onClick={(e)=>{acceptPile(pile.id,myRegistration.id)}}>Accept</AcceptButton>
																					<DeclineButton className="declinebutton" onClick={()=>{declinePile(pile.id,myRegistration.id)}}>Decline</DeclineButton>																			
																				</>}
																		</Col>
																	</Row>
																	<PileMembers ref={thisRef}>
																		{ pile.members.map((m) => { if(m.registrationId !== myRegistration.id && (m.status!=='declined' && m.status!=='removed')) return( 
																		<OverlayTrigger
																			placement="bottom"
																			key={"overlay_"+m.registrationId}
																			delay={{ show: 100, hide: 100 }}
																			overlay={<Tooltip id="pilemembertooltip" {...props}>
																									<b>{m.name}</b><br /><small>
																									{(m.status==='accepted' || m.status==='creator')?'(Member)':'(Invited)'}</small>
																								</Tooltip>}>
																			<Link to={"pack/meet/"+m.registrationId}><ProfilePic key={"photo_"+m.registrationId} image={(!m.profilePic) ? process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[m.role] : process.env.REACT_APP_API_HOST+m.profilePic+'?size=small&bearer='+authToken.token} /></Link>
																		</OverlayTrigger>
																		); else return null;} )}
																	</PileMembers>
			 													</InviteLine>)}
																) }
													<RegularButton className="full-width" onClick={()=>{ startCreatePuppyPile(); }}>Create a Puppy Pile</RegularButton>
												</Col>
											</Row> } 
				{ showPuppyPileOverview && <Row className="puppyPileInfo">
														<Col>
															<BreadcrumbLink onClick={()=>{ setShowPuppyPileOverview(false); }} ><FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" /> Back to My Puppy Pile</BreadcrumbLink>
															<h5>About Puppy Piles</h5>
															<p>When it's time to assign cabins and yurts, we keep Puppy Piles together. Here's how this works:</p>
															<ul>
																<li>Only guests who have <i>accepted</i> a Puppy Pile invite will be included. If you see "Invited" or "Declined" by 
																	their name, then they have not yet accepted the invite.</li>
																<li>Guests can only be in one Puppy Pile. You have to decline one to accept another.</li>
																<li>You can add up to 8 people in a single Puppy Pile.</li>
																<li>Puppy Piles can specify their Housing Type Preference (Cabin, Yurt, or Either) and we'll do our best to 
																	accommodate that (but cannot guarantee it). Your Puppy Pile's preference will take precedence over your individual
																  preference. Guests' individual mobility needs take precedence over all preferences for the whole Puppy Pile.</li> 
																<li>If you have other requests for your Puppy Pile housing, such as wanting to be near another Puppy Pile, you can
																	enter that in the "Other Requests" preference. <b>Note:</b> We probably won't read these until very close
																	to the event, so if you have something particularly important please send the Directors a Message.</li>
																<li>If your Puppy Pile is assigned multiple cabin rooms, you can shuffle around in those rooms however you like.</li>
															</ul>
															<p>A Puppy Pile's creator is identified as the Alpha and the Alpha speaks for the Puppy Pile if the Directors 
																need to ask any questions. The Alpha can grant permissions to other members to edit the preferences or invite
																more guests to join the Pile.</p>
														</Col> 
														</Row> }

			{!loadingPuppyPileInfo && doSwitchPuppyPiles && !creatingPuppyPile && !showPuppyPileOverview && <Row>
												<Col>
													<p>You can switch to a different Puppy Pile by accepting the invitation from this list, or you can create a new one and invite others. 
													You'll be removed from your current Puppy Pile. If you have concerns about Housing, please send a Message to the Directors.</p>
													
													<BreadcrumbLink onClick={()=>{ setDoSwitchPuppyPiles(false); }} ><FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" /> Nevermind, Back to My Puppy Pile</BreadcrumbLink>
												</Col>
												<Col>
													{puppyPiles.map((pile) => { var thisRef = React.createRef();
																										 var myStatusInPile = ''; 
																										 for(var i in pile.members){ 
																											 if(pile.members[i].registrationId === myRegistration.id){ 
																												 if(pile.members[i].status==='accepted' || pile.members[i].status==='declined') myStatusInPile = pile.members[i].status;  
																											 } 
																										 } 
																										 return (
																<InviteLine key={pile.id} className={ "puppyPileInvitationLine " + myStatusInPile }>
				 													<Row>
																		<Col sm={7}>
																			<p><b>{pile.name}</b><br /><DeclinableTextLink onClick={(e)=>showPileMembers(e.target,thisRef)}>See Who's In This Pile</DeclinableTextLink></p>
																		</Col>
																		<Col sm={5}>
																			{pileAcceptDeclineButtonSpinners[pile.id] ? 
																				<Spinner animation="grow" variant="success" />
																				:
																				<>
																				{myPuppyPile.id!==pile.id && <AcceptButton onClick={()=>{acceptPile(pile.id,myRegistration.id)}}>Accept</AcceptButton>}
																			 	<DeclineButton className="declinebutton" onClick={()=>{declinePile(pile.id,myRegistration.id)}}>{myPuppyPile.id===pile.id ? 'Leave':'Decline'}</DeclineButton>
																			 </>}
																		</Col>
																	</Row>
																	<PileMembers ref={thisRef}>
																		{ pile.members.map((m) => { if(m.registrationId !== myRegistration.id && (m.status!=='declined' && m.status!=='removed')) return( 
																		<OverlayTrigger
																			placement="bottom"
																			key={"overlay_"+m.registrationId}
																			delay={{ show: 100, hide: 100 }}
																			overlay={<Tooltip id="pilemembertooltip" {...props}>
																									<b>{m.name}</b><br /><small>
																									{(m.status==='accepted' || m.status==='creator')?'(Member)':'(Invited)'}</small>
																								</Tooltip>}>
																			<Link to={"pack/meet/"+m.registrationId}><ProfilePic key={"photo_"+m.registrationId} image={(!m.profilePic) ? process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[m.role] : process.env.REACT_APP_API_HOST+m.profilePic+'?size=small&bearer='+authToken.token} /></Link>
																		</OverlayTrigger>
																		); else return null;} )}
																	</PileMembers>
			 													</InviteLine>)}
																) }
													<RegularButton className="full-width" onClick={()=>{ startCreatePuppyPile(); }}>Create a Puppy Pile</RegularButton>
												</Col>
											</Row> } 
			{myPuppyPile && !doSwitchPuppyPiles && !creatingPuppyPile && !showPuppyPileOverview && !loadingPuppyPileInfo && <>
												<Row>
													<Col sm={12} md={10} className="puppyPileName">
														<h6>{myPuppyPile.name}</h6>
													</Col>
													<Col sm={12} md={2} className="puppyPileActions">
														<DropdownButton id="puppyPileActions" title="Actions" alignRight>
																{mayEditPile && <Dropdown.Item as={Button} onClick={()=>{setDoEditPuppyPilePrefs(true);}}>Edit Preferences</Dropdown.Item>}
																{mayAddMembers && !myPuppyPileIsCreator && <Dropdown.Item as={Button} onClick={()=>{setDoInviteNewMembers(true);}}>Invite Others</Dropdown.Item>}
																{myPuppyPileIsCreator && <Dropdown.Item as={Button} onClick={()=>{startEditPileMembers();}}>Add/Edit Members</Dropdown.Item>  }
																{myPuppyPileIsCreator ? <Dropdown.Item as={Button} onClick={()=>{setShowDeletePuppyPileConfirmation(true);}}>Delete Puppy Pile</Dropdown.Item> : <Dropdown.Item as={Button} onClick={()=>{setDoSwitchPuppyPiles(true);}}>Switch Puppy Piles</Dropdown.Item> }
														</DropdownButton>
													</Col>
												</Row>
										
												{ !doEditPuppyPilePrefs && !showPuppyPileOverview && 
												<Row className="puppyPilePrefs">
													<Col sm={12} md={2}>Pile Preferences: </Col> 
													<Col sm={12} md={3}><b>Housing Type: </b> 
													{ myPuppyPile.housingTypePreference ? 
															(myPuppyPile.housingTypePreference==='either'?
															 	"Either Yurt or Cabin"
															 	:
															  myPuppyPile.housingTypePreference.charAt(0).toUpperCase() + myPuppyPile.housingTypePreference.slice(1))
													:
													"Not Set"}</Col>
													<Col sm={12} md={7}><b>Requests:</b> {myPuppyPile.housingRequests ? myPuppyPile.housingRequests:"None Entered"}</Col>
												</Row> }
												
			 									{ doEditPuppyPilePrefs && !showPuppyPileOverview && 
												<Row className="editPuppyPilePrefs">
													<Col>
														<Row>
															<Col className="header">Edit Puppy Pile Preferences</Col>
														</Row>
														<Row>
															<Col md={12} lg={3}>
																<Form.Group> 
																	<FormLabel.Primary>Name</FormLabel.Primary>
																	<Form.Control value={editPileName} onChange={(e)=>{setEditPileName(e.target.value)}} />
																</Form.Group>
															</Col>
															<Col md={12} lg={3}>
																<Form.Group> 
																	<FormLabel.Primary>Housing Preference</FormLabel.Primary>
																	<Form.Control id="editpile_housingtype" as="select" value={editPileHousingType} onChange={e => { setEditPileHousingType(e.target.value); }} >
																			<option></option>
																			<option value="cabin">Cabin</option>
																			<option value="yurt">Yurt</option>
																			<option value="either">Either</option>
																	</Form.Control>
																</Form.Group>
															</Col>
															<Col md={12} lg={6}>
																<Form.Group> 
																	<FormLabel.Primary>Special Requests</FormLabel.Primary>
																	<Form.Control value={editPileRequests} onChange={(e)=>{setEditPileRequests(e.target.value)}} />
																</Form.Group>
															</Col>
														</Row>
														<Row>
															<Col className="buttonRow">
																{ isSavingPrefs ? <Spinner animation="grow" variant="warning" />:<>
																<RegularButton className="gray inverse" onClick={()=>{setDoEditPuppyPilePrefs(false);}}>Cancel</RegularButton>
																<RegularButton onClick={savePuppyPilePreferences}>Save Changes</RegularButton></> }
															</Col>
														</Row>
													</Col>
												</Row> }
			 
			 									{ !doEditPuppyMembers && !doInviteNewMembers && !showPuppyPileOverview  && <>
												<Row>
													<Col md={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }}>
														<div className="puppyPileMembersList">
														<div className="puppyPileMemberHeader">Who's In This Pile</div>
														<div className="puppyPileMembersListBody">
														{myPuppyPile.members.map((member)=>{    
															if(member.status!=='declined' && member.status!=='removed'){
																return <Link key={"pilememberrow_"+member.registrationId} to={"pack/meet/"+member.registrationId} className={"puppyPileMemberRow "+member.status}>
																	<img src={member.profilePic?process.env.REACT_APP_API_HOST+member.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[member.role]} alt={member.name} width="50" height="50" />
																	<div>
																		{member.name}<br/>
																		<small>{ member.status==='invited'?'Invited':(member.status==='creator'?'Alpha • ':'')} 
																			{(member.status!=='invited')?(member.housingTypePreference==='yurt'?'Prefers a Yurt':((member.housingTypePreference==='cabin')?'Prefers a Cabin':((member.housingTypePreference==='either')?((member.status==='creator')?'Cabin or Yurt':'Either Cabin or Yurt'):'No Preference Set'))):''}</small>
																	</div>
																</Link>
															} else { return null; } 
															}
														)}
														</div>
														</div>
													</Col>
													<Col md={{ span: 12, order: 1 }} lg={{ span: 9, order: 2 }}>
														<div className="puppyPileChat">
															<div className="puppyPileChatHeader">Puppy Pile Chat</div>
															<PuppyPileChat puppyPile={myPuppyPile} />
														</div>
													</Col>
												</Row>
												<Row>
													<Col className="learnMoreLink">
														<ButtonLink onClick={()=>{ setShowPuppyPileOverview(true); }} className="single-line"><FontAwesomeIcon icon={['far', 'info-circle']} />Learn More about Puppy Piles</ButtonLink>
													</Col>
												</Row>
												</>}
			 
			 									{ doEditPuppyMembers && !showPuppyPileOverview && 
												<Row className="editPuppyPileMembers">
													<Col>
														<Row>
															<Col className="header">Edit Puppy Pile Members</Col>
														</Row>
														{ 
														myPuppyPile.members.map((member)=> {
															if(member.status!=='creator'){
															return (
															<Row key={member.registrationId} className="editPuppyPileMemberRow">
																<Col lg={3}>
																	<div className="puppyPileMemberRow nohover">
																	<img src={member.profilePic?process.env.REACT_APP_API_HOST+member.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[member.role]} alt={member.name} width="50" height="50" />
																	<div>
																		<span className={member.status==='declined'?'declined':''}>{member.name}</span><br/>
																	<small>{ (member.unsaved)?'Save to Invite':(member.status==='invited'?'Invited':(member.status==='creator'?'Alpha • ':(member.status==='declined'?'Declined':'')))} 
																		{(member.status!=='invited')?(member.housingTypePreference==='yurt'?'Prefers a Yurt':((member.housingTypePreference==='cabin')?'Prefers a Cabin':((member.housingTypePreference==='either')?'No Preference':''))):''}</small>
																	</div>
																	</div>
																</Col>
																<Col lg={3}>
																	<Form.Group>
																		<Checkbox checked={editMemberPermissions.get(member.registrationId+'_editpile')} label="May Edit Preferences" onCheck={() => {handleEditMemberToggle('editpile',member.registrationId)}} />
																	</Form.Group>
																</Col>
																<Col lg={3}>
																	<Form.Group>
																		<Checkbox checked={editMemberPermissions.get(member.registrationId+'_addmembers')} label="May Add Members" onCheck={() => {handleEditMemberToggle('addmembers',member.registrationId)}} />
																	</Form.Group>
																</Col>
																<Col lg={3}>
																	{ member.status!=='declined' && member.status!=='invited' && member.status!=='removed' && <Button variant="secondary" onClick={()=>{setShowMakeAlphaConfirmation(true); setHoldMakeAlphaId(member.registrationId); }}>Make Alpha</Button>}
																	<Button variant="danger" onClick={()=>{setShowRemoveMemberConfirmation(true); setHoldRemoveMemberId(member.registrationId); }}>Remove</Button>
																</Col>
															</Row>);} else { return null; } }
														)
														}
														<Row>
															<Col lg={8} md={12}>
															{ myPuppyPile.members.length >=8 ? <p>Your Puppy Pile has reached 8 members (which includes those who have been invited but not accepted yet). If you would like to add more, you can reach out to the Directors to request a yurt and we can manually add people to your Puppy Pile. Up to 12 people can be in a yurt.</p> : <>
																<Form.Group>
																		<FormLabel.Primary>Invite Another Guest:</FormLabel.Primary>
																	  <Typeahead
																			clearButton
																			id="inviteMoreToPuppyPileSearcher"
																			labelKey="name"
																			flip
																			highlightOnlyResult
																			minLength={2}
																			ref={editPuppyPileAddMemberRef}   
																			options={autoCompleteList}
																			placeholder="Start typing the name of a guest"
																			onChange={onSelectAddMemberToPile}
																			renderMenuItemChildren = {(option, { text }, index) => (
																					<div>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="45"/>
																						<Highlighter search={text}>
																						{option.name}
																						</Highlighter>
																					</div>
																				)}
																			renderToken = {(option, { onRemove }, index) => (
																					<Token
																						key={index}
																						onRemove={onRemove}
																						option={option}>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="30" height="30" />
																						{option.name}
																					</Token>
																				)}
																		/>
																	</Form.Group>
																	</>}
															</Col>
															<Col className="buttonRow" lg={4} md={12}>
																{ isSavingMembers ? <Spinner animation="grow" variant="warning" />:<>
																<RegularButton className="gray inverse" onClick={()=>{cancelEditPileMembers();}}>Cancel</RegularButton>
																<RegularButton onClick={savePuppyPileMemberChanges}>Save Changes</RegularButton></>}
															</Col>
														</Row> 
													</Col>
												</Row>}

			 									{ doInviteNewMembers && !showPuppyPileOverview && 
												<Row className="editPuppyPileMembers">
													<Col>
														<Row>
															<Col className="header">Invite Others To Your Puppy Pile</Col>
														</Row>
														<Row>
															<Col lg={8} md={12}>
																<Form.Group>
																		<FormLabel.Primary>Who Do You Want to Invite?</FormLabel.Primary>
																	  <Typeahead
																			clearButton
																			id="inviteMoreToPuppyPileSearcherBetas"
																			labelKey="name"
																			flip
																			multiple
																			highlightOnlyResult
																			minLength={2}
																			ref={inviteMembersToPPRef}
																			options={autoCompleteList}
																			placeholder="Start typing the name of a guest"
																			onChange={setMembersSelectedToInviteToPuppyPile}
																			renderMenuItemChildren = {(option, { text }, index) => (
																					<div>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="45"/>
																						<Highlighter search={text}>
																						{option.name}
																						</Highlighter>
																					</div>
																				)}
																			renderToken = {(option, { onRemove }, index) => (
																					<Token
																						key={index}
																						onRemove={onRemove}
																						option={option}>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="30" height="30" />
																						{option.name}
																					</Token>
																				)}
																		/>
																	</Form.Group>
															</Col>
															<Col className="buttonRow" lg={4} md={12}>
																{ isSavingMembers ? <Spinner animation="grow" variant="warning" />:<>
																<RegularButton className="gray inverse" onClick={()=>{cancelInvitationWorkflow();}}>Cancel</RegularButton>
																<RegularButton onClick={goInviteAdditionalMembers}>Save Changes</RegularButton></>}
															</Col>
														</Row> 
													</Col>
												</Row>}
											</>} 
			{creatingPuppyPile && <>
														<Row>
															<Col md={12} lg={3}>
																<Form.Group> 
																	<FormLabel.Primary>Name</FormLabel.Primary>
																	<Form.Control value={newPileName} onChange={(e)=>{setNewPileName(e.target.value)}} />
																</Form.Group>
															</Col>
															<Col md={12} lg={6}>
																	<FormLabel.Primary>Invite Members</FormLabel.Primary>
																	  <Typeahead
																			clearButton
																			id="addToPuppyPileSearcher"
																			labelKey="name"
																			flip
																			multiple
																			highlightOnlyResult
																			minLength={2}
																			options={autoCompleteList}
																			placeholder="Start typing the name of a guest"
																			onChange={setMembersSelectedForNewPuppyPile}
																			renderMenuItemChildren = {(option, { text }, index) => (
																					<div>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="45"/>
																						<Highlighter search={text}>
																						{option.name}
																						</Highlighter>
																					</div>
																				)}
																			renderToken = {(option, { onRemove }, index) => (
																					<Token
																						key={index}
																						onRemove={onRemove}
																						option={option}>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="30" height="30" />
																						{option.name}
																					</Token>
																				)}
																		/>
															</Col>
															<Col md={12} lg={3}>
																<Form.Group> 
																	<FormLabel.Primary>&nbsp;</FormLabel.Primary>
																	{ isCreatingPile ?  <Spinner animation="grow" variant="success" />:<CreateButton onClick={goCreatePuppyPile}>Create Puppy Pile</CreateButton>}
																</Form.Group>
															</Col>
														</Row>
														<Row>
															<Col>
																<BreadcrumbLink onClick={()=>{ setCreatingPuppyPile(false); }} ><FontAwesomeIcon icon={['fas', 'arrow-left']} size="lg" /> Nevermind, Cancel New Puppy Pile</BreadcrumbLink>
															</Col>
														</Row>
														</>}

      <Modal show={showDeletePuppyPileConfirmation} onHide={()=>{setShowDeletePuppyPileConfirmation(false);}}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this Puppy Pile? All other members will be removed and have to find or create another one.<br /><br />
					If you only want to switch Puppy Piles but leave this one intact, designate a new Alpha by choosing "Add/Edit Members" from the Actions menu. </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShowDeletePuppyPileConfirmation(false);}}>Cancel</Button>
          <Button variant="danger" onClick={()=>{ setLoadingPuppyPileInfo(true); setShowDeletePuppyPileConfirmation(false); confirmPileDelete() }}>Delete It</Button>
        </Modal.Footer>
      </Modal>
			
      <Modal show={showRemoveMemberConfirmation} onHide={()=>{setShowRemoveMemberConfirmation(false); setHoldRemoveMemberId(null); }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Removal</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to remove this member? They will not see the invitation to the Puppy Pile anymore. You can re-invite them later.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{setShowRemoveMemberConfirmation(false); setHoldRemoveMemberId(null); }}>Cancel</Button>
          <Button variant="danger" onClick={confirmRemoveMember}>Remove Member</Button>
        </Modal.Footer>
      </Modal>
			
      <Modal show={showMakeAlphaConfirmation} onHide={()=>{setShowMakeAlphaConfirmation(false); setHoldMakeAlphaId(null); }}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Change in Alpha</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to change the Alpha for this Puppy Pile? Once you confirm the change, you will no longer be able to make changes 
				to members' permissions. This change will take effect immediately, and all other membership changes (including additions, removals, and permissions) will be saved now.</Modal.Body>
        <Modal.Footer>
					{ isChangingAlpha ? <Spinner animation="grow" variant="success" />:<>
          <Button variant="secondary" onClick={()=>{setShowMakeAlphaConfirmation(false); setHoldMakeAlphaId(null); }}>Cancel</Button>
          <Button variant="success" onClick={confirmMakeAlpha}>Confirm</Button></>}
        </Modal.Footer>
      </Modal>
						</Container>
					);
}
 
export default PuppyPile;