import React, { useState, useEffect }  from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import styled from 'styled-components';

import {useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { FormWrapper, SubmitButton, FormLabel, SaveSpinnerContainer } from "../../styled-components/Forms.js";

import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Alert from 'react-bootstrap/Alert'

const CCInputWrapper = styled.div`
		display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
`; 

function CreditCardForm(props) {
  const { authToken, setAuthToken } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
	
	const [stripePaymentKey, setStripePaymentKey] = useState('');
	const [paymentId, setPaymentId] = useState('');
	const [nameOnCard, setNameOnCard] = useState('');
	const [billingZip, setBillingZip] = useState('');
	const [chargingCard, setChargingCard] = useState(false);
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState(false);
	
	const amountToCharge = props.amount;
	const successCallback = props.onSuccess; 
	const isDonation = props.paymentType==='donation'; 
	const isLinenRental = props.paymentType==='linens'; 

	const CARD_ELEMENT_OPTIONS = {
		style: {
			base: {
				padding: "12px 6px",
				fontSize: "16px",
				fontWeight: "400",
				lineHeight: "1.5",
				color: "#495057",
				backgroundColor: "#fff",
				fontFamily: 'Courier, fixed-width',
				fontSmoothing: "antialiased",
				"::placeholder": {
					color: "#aab7c4",
				},
			},
			invalid: {
				color: "#b82a34",
				iconColor: "#b82a34",
			},
		},
	};

	function createPaymentRecord(){ 
		var paymentDetail = {amount:amountToCharge,description:"PITM Registration Payment",type:"registration"}; 
		if(isDonation){ 
			paymentDetail.description = "Donation to PITM, Inc"; 
			paymentDetail.type = "donation"; 
		}
		if(isLinenRental){
			paymentDetail.description = "Linen Rental"; 
			paymentDetail.type = "linenrental"; 
		} 
		axios.post(process.env.REACT_APP_API_HOST+"/registrations/mine/payments",paymentDetail,{headers: {'Bearer': authToken.token}})
      		.then(result => {
									if (result.status === 200) {
										setStripePaymentKey(result.data.intentCompletionKey); 
										setPaymentId(result.data.id); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
			
								});
	}
	// this disables React's strict checking on this one-time effect, as the warning is irrelevant in this case. 
	// eslint-disable-next-line
	useEffect(() => { createPaymentRecord(); },[]); 
	
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
		setChargingCard(true); 
		setIsError(false);

    const result = await stripe.confirmCardPayment(stripePaymentKey, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
        billing_details: {
          name: nameOnCard,
					address: { postal_code: billingZip }
        },
      }
    });

    if (result.error) {
			setChargingCard(false); 
			setIsError(true);
			setErrorMessage(result.error.message); 
    } else {
      if (result.paymentIntent.status === 'succeeded') {
				successCallback(paymentId); 
      }
    }
  };
	
	const LoadingSpinner = <SaveSpinnerContainer><Spinner animation="grow" variant="success" /></SaveSpinnerContainer>
	
	const CCForm = <FormWrapper onSubmit={handleSubmit}>
					{isError && <Form.Row>
							<Form.Group as={Col}>
								<Alert variant="danger">{errorMessage}</Alert>
							</Form.Group>
						</Form.Row>
					}
						<Form.Row>
							<Form.Group as={Col}>
								<FormLabel.Primary>Name On Card</FormLabel.Primary>
								<Form.Control placeholder="" id="nameOnCard" value={nameOnCard} onChange={e => { setNameOnCard(e.target.value); }}/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<FormLabel.Primary as={Col} sm={12}>Card Info</FormLabel.Primary>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								<FormLabel.Secondary>Card Number</FormLabel.Secondary>
								<CCInputWrapper>
									<CardNumberElement options={CARD_ELEMENT_OPTIONS} />
								</CCInputWrapper>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}  sm={6}>
								<FormLabel.Secondary>Expiration Date</FormLabel.Secondary>
								<CCInputWrapper>
									<CardExpiryElement options={CARD_ELEMENT_OPTIONS} />
								</CCInputWrapper>
							</Form.Group>
							<Form.Group as={Col}  sm={6}>
								<FormLabel.Secondary>Security Code</FormLabel.Secondary>
								<CCInputWrapper>
									<CardCvcElement options={CARD_ELEMENT_OPTIONS} />
								</CCInputWrapper>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								<FormLabel.Primary>Billing Zip Code</FormLabel.Primary>
								<Form.Control placeholder="" id="nameOnCard" value={billingZip} onChange={e => { setBillingZip(e.target.value); }}/>
							</Form.Group>
						</Form.Row>
						<Form.Row>
							<Form.Group as={Col}>
								{chargingCard && <Spinner animation="grow" variant="success" />}
								{!chargingCard && <SubmitButton disabled={!stripe} value="Pay Now" className="right"/>}
							</Form.Group>
						</Form.Row>
					</FormWrapper> 
	
	return (<>
		{!stripePaymentKey && LoadingSpinner}
		{stripePaymentKey && CCForm}
					</>
	);
}
 
export default CreditCardForm;