import React, { useState }  from "react";
import styled from 'styled-components';


import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

const ReceiptLine = styled(Row)`
	width:100%; 
	height:63px;
	border-bottom:1px solid #EEEEEE; 
	position:relative; 
	margin-right:0px; 
	margin-left:0px; 
	:last-of-type{ 
		border-bottom:none; 
		border-bottom-right-radius: 10px; 
		border-bottom-left-radius: 10px; 
	}
	:last-of-type .col-sm-3:last-of-type{ 
		border-bottom-right-radius: 10px; 
	} 
`; 
const AmountCol = styled(Col)`
	background-color:#DEDEDE; 
	font-weight:bold; 
	text-align:center; 
	vertical-align:middle; 
	height:100%; 
	position:relative; 
	padding-top:15px; 
`; 

const ReceiptLineDescription = styled(Col)`
	padding-top:10px; 
`; 

const ReceiptLineDetail = styled(Col)`
	font-size:12px; 
	color:#999; 
`; 

function Receipt(props) {
	const [item] = useState(props.receipt); 
	
	const receiptDate = new Date(item.completedAt); 
	
	
	function datePrinter(date){ 
		var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
		var string = months[date.getMonth()]+' '+date.getDate()+', '+date.getFullYear(); 
		return string; 
	} 

	return (<ReceiptLine className="receiptLine">
						<Col xs={9}>
							{ (item.paymentMethod==='Discount Code') && <>
								<Row>
									<ReceiptLineDescription>Discount Code</ReceiptLineDescription>
								</Row>
								<Row>
									<ReceiptLineDetail>Used discount code {item.description} at registration</ReceiptLineDetail>
								</Row>
							</>
							}
							{ (item.paymentMethod==='Card Payment') && <>
								<Row>
									<ReceiptLineDescription>{item.description}</ReceiptLineDescription>
								</Row>
								<Row>
									<ReceiptLineDetail>Paid on {datePrinter(receiptDate)} with card ending {item.lastFour}</ReceiptLineDetail>
								</Row>
							</>
							}
						</Col>
						<AmountCol xs={3}>${item.amount}</AmountCol>
					</ReceiptLine>
	);
}
 
export default Receipt;