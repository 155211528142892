import React, {useState, useEffect} from "react";
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; 
import { faSquare } from '@fortawesome/free-regular-svg-icons'; 


library.add(
  faCheck,
  faSquare
);

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
	// Hide checkbox visually but remain accessible to screen readers.
	// Source: https://polished.js.org/docs/#hidevisually
	border: 0;
	clip: rect(0 0 0 0);
	clippath: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	white-space: nowrap;
	width: 1px;
`;

const StyledCheckbox = styled.div`
	display: inline-block;
	width: 25px;
	height: 20px;
	transition: all 150ms;
	color: ${props => props.checked ? '#0d9348' : '#666666'};
`;
const CheckboxContainer = styled.div`
	display: inline-block;
	vertical-align: bottom;

`;
function Checkbox(props) {
	const [checked, setChecked] = useState(props.checked);
	const [label,setLabel] = useState(props.label);
	const upComponentHandler = props.onCheck; 
	
	useEffect(() => {
    setChecked(props.checked);
    setLabel(props.label);
  }, [props]);
	
	function onCheck(){ 
		setChecked(!checked); 
 		upComponentHandler(); 
	} 
	return (<>
			<div className="rook-checkbox">
        <label>
					<CheckboxContainer>
						<HiddenCheckbox checked={checked} onChange={onCheck}/>
						<StyledCheckbox checked={checked}>
							{ checked ? <FontAwesomeIcon icon={['fas', 'check']} size="lg" /> : <FontAwesomeIcon icon={['far', 'square']} size="lg" /> }
						</StyledCheckbox>
					</CheckboxContainer>
          <span>{label}</span>
        </label>
      </div>    

	</>);
} 
export default Checkbox;