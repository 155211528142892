import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useAuth } from "../../contexts/auth";

import Switch from '../../custom-widgets/Switch.js'; 

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Spinner from 'react-bootstrap/Spinner'
import Fade from 'react-bootstrap/Fade'
import Modal from 'react-bootstrap/Modal'

import { FormLabel,RegularButton } from "../../styled-components/Forms.js";
import { AddPhotoButton } from "../../styled-components/Profile.js";

import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'

import bsCustomFileInput from 'bs-custom-file-input';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { library } from '@fortawesome/fontawesome-svg-core';
// import { faCamera } from '@fortawesome/pro-regular-svg-icons'; 

// library.add(
// 	faCamera
// );

function ProfilePhotoAdder(props) {
	const { myRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
	const [show,setShow] = useState(false); 
  const [ makePhotoPrimary, setMakePhotoPrimary ] = useState(props.makePrimary);
  const [ newPhotoCaption, setNewPhotoCaption ] = useState('');
// 	const [selectedPhotoToUpload, setSelectedPhotoToUpload ] = useState(null); 
	const [saveActionInProgress,setSaveActionInProgress] = useState(false); 

	const onSave = props.onSave; 
	const onCancel = props.onCancel; 
	
	const [crop,setCrop] = useState({
                unit: "%",
                width: 900,
                aspect: 1 / 1
            }); 
	const [croppedImage,setCroppedImage] = useState(null); 
// 	const [croppedImageUrl,setCroppedImageUrl] = useState(null); 
	const [src,setSrc] = useState(null); 
// 	const [imageRef,setImageRef] = useState(null); 
	
	const [showImageCropper,setShowImageCropper] = useState(null); 
	
	let imageRef; 
	
	function fileSelectHandler(e){ 
    const fileReader = new FileReader()
    fileReader.onloadend = () => {
			setSrc(fileReader.result);
    }   
    fileReader.readAsDataURL(e.target.files[0]);
		setShowImageCropper(true); 
	} 

	function handleCloseImageCropper(){ 
		setShowImageCropper(false); 
		
	} 
	function postCropSaveImage(){ 
		setShowImageCropper(false); 
		doPhotoUpload(); 
	} 
	
	function doPhotoUpload(){ 
		
		const formData = new FormData();
		
		setSaveActionInProgress(true); 
		if(croppedImage===null) getCroppedImg(imageRef,crop); 
    formData.append("photo", croppedImage);
    formData.append("caption", newPhotoCaption);
    formData.append("makePrimary", (makePhotoPrimary)?'true':'false' );
		
		axios.post(process.env.REACT_APP_API_HOST+"/registrations/"+myRegistration.id+"/photos",formData,{headers:{'Bearer':authToken.token,'Content-Type':'multipart/form-data'}})
				.then(result => {
								if (result.status === 200) {
									setTimeout(()=>{
// 										setSelectedPhotoToUpload(null); 
										setNewPhotoCaption(''); 
										setMakePhotoPrimary(false); 
										onSave(result.data); 
										setSaveActionInProgress(false); 
									},1000); 
									if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
								} else {
								}
							}).catch(e => {
							});
	} 
	
	
	
	function onImageLoaded(image){
//     setImageRef(image); 
		imageRef = image; 
// 		setCrop(crop); 
// 		getCroppedImg(image,crop); 
	}

	function onCropChange(crop){
		setCrop(crop); 
	}

	function onCropComplete(localcrop){
    if (imageRef && localcrop.width && localcrop.height) {
// 			setCroppedImageUrl(getCroppedImg(imageRef,localcrop)); 
			getCroppedImg(imageRef,localcrop); 
    }
	}

	function getCroppedImg(image, localcrop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = localcrop.width;
    canvas.height = localcrop.height;
    const ctx = canvas.getContext("2d");
		ctx.imageSmoothingEnabled = true; 
    ctx.imageSmoothingQuality = 'high'; 
    ctx.drawImage(
        image,
        localcrop.x * scaleX,
        localcrop.y * scaleY,
        localcrop.width * scaleX,
        localcrop.height * scaleY,
        0,
        0,
        localcrop.width,
        localcrop.height
     )

    const reader = new FileReader()
    canvas.toBlob(blob => {
        reader.readAsDataURL(blob)
        reader.onloadend = () => {
            dataURLtoFile(reader.result, 'cropped.jpg')
        }
    },'image/jpeg')
	}
	
	function dataURLtoFile(dataurl, filename) {
		console.log('dataurltofile'); 
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    let thiscroppedImage = new File([u8arr], filename, {type:mime});
		setCroppedImage(thiscroppedImage)
		console.log(croppedImage); 
//     this.setState({croppedImage: croppedImage }) 
	}
	
  bsCustomFileInput.init();

	useEffect(() => {
		setShow(true); 
	},[]); 
	
  return (<><Fade in={show}>
					<Container>
						<Form.Group as={Row}>
							<FormLabel.Primary>Choose a Photo to Upload</FormLabel.Primary>
							<Form.File id="addprofilephoto" label="Select a Profile Photo" accept="image/jpeg" custom onChange = {fileSelectHandler} />
						</Form.Group>
						<Form.Group as={Row}>
							<FormLabel.Primary>Caption</FormLabel.Primary>
							<Form.Control as="textarea" rows="3" />
						</Form.Group>
						<Form.Group as={Row}>
								<Switch isOn={makePhotoPrimary} onColor="#0d9948" handleToggle={() => {setMakePhotoPrimary(!makePhotoPrimary); }} id="profile_addphotoasprimary" />
								<Form.Label htmlFor="profile_addphotoasprimary">Make This My Primary Photo</Form.Label>
						</Form.Group>
						<Row>
											{saveActionInProgress && <Spinner animation="grow" variant="success" /> }
											{!saveActionInProgress && <>
												<AddPhotoButton onClick={doPhotoUpload}>Save</AddPhotoButton>
												<AddPhotoButton className="inverse" onClick={()=>{ /* setSelectedPhotoToUpload(null); */ setNewPhotoCaption(''); onCancel(); }}>Cancel</AddPhotoButton> 
											 	</>}
											 
							
						</Row>
						</Container>
</Fade>
		
      <Modal
        show={showImageCropper}
        onHide={handleCloseImageCropper}
        backdrop="static"
        keyboard={false}
				centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Crop Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {src && (
                <ReactCrop
                  src={src}
                  crop={crop}
                  onImageLoaded={onImageLoaded}
                  onComplete={onCropComplete}
                  onChange={onCropChange}
                 /> 
            )}
        </Modal.Body>
        <Modal.Footer>
          <RegularButton onClick={handleCloseImageCropper}>
            Cancel
          </RegularButton>
          <RegularButton onClick={postCropSaveImage}>Save Image</RegularButton>
        </Modal.Footer>
      </Modal>
						</>
    );
}
 
export default ProfilePhotoAdder;