import React, { useState,useEffect }  from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
// import { useMyRegistration } from "../../contexts/myregistration.js";

import { LoadingSpinner } from "../../styled-components/AuthForms.js";
import { TextLink,WideButtonLink } from "../../styled-components/SmallElements.js";

import Container from 'react-bootstrap/Container'
// import Row from 'react-bootstrap/Row'
// import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'


import './Pack.css'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
} from '@fortawesome/free-brands-svg-icons';
	
library.add(
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost
);

function Pack(props) {
// 	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
	
	const [ guestList,setGuestList ] = useState([]); 
	
	let defaultProfilePics = {
			pup:"/profile_generic_pup.png",
			dog:"/profile_generic_pup.png",
			cat:"/profile_generic_cat.png",
			pony:"/profile_generic_pony.png",
			'other-critter':"/profile_generic_other.png",
			handler:"/profile_generic_human.png",
			parent:"/profile_generic_human.png",
			trainer:"/profile_generic_human.png",
			friend:"/profile_generic_human.png",
			other:"/profile_generic_human.png"
}


	useEffect(()=>{
		
		
		axios.get(process.env.REACT_APP_API_HOST+"/registrations?status=valid",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										
										setGuestList(result.data.registrations); 
										 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
										
									} else {
									}
								}).catch(e => {
								});	
		
	// eslint-disable-next-line
	},[]); 
	
	
	
	
	
    return (<>
						<Container>
						<h1>Meet The Pack</h1>
				
				{guestList.length===0 && 
							<LoadingSpinner>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />
						</LoadingSpinner>}
				
				{guestList.map((person)=>{ 
					
					var photo; 
					if(person.profile.photos.length>0){ 
						for(var i in person.profile.photos){ 
							if(person.profile.photos[i].isPrimary){ 
								photo = process.env.REACT_APP_API_HOST+person.profile.photos[i].uri+'?size=medium&bearer='+authToken.token;
							} 
						} 
					} 
					if(photo===undefined){ 
						photo = process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[person.guest.role];
					} 
					
					
					
					
return (
	<Card className="packListCard" key={person.id} onClick={()=>{window.location.href="/pack/meet/"+person.id;}}>
		<div className="photo">
			<Card.Img variant="top" src={photo} />
			<div className="badges">
				{person.guest.guestType==='director' && <span className="typebadge director">Director</span>}
				{person.guest.guestType==='volunteer' && <span className="typebadge volunteer">Volunteer</span>}
				{person.guest.eventJob!==null && <span className="typebadge job">{person.guest.eventJob}</span>}
			</div>
		</div>
  <Card.Body>
	
    <div className="card-text">
			<div className="cardcontent">	
					<h5 className="guestName">{person.guest.sceneName}</h5>
					<p className="bio">{person.profile.bio!=null && person.profile.bio.replace( /(<([^>]+)>)/ig, '')}</p>
			</div>
			<div className="links">
				{person.profile.socialMedia.facebook!==null && person.profile.socialMedia.facebook!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://www.facebook.com/"+person.profile.socialMedia.facebook);}}><FontAwesomeIcon icon={['fab', 'facebook']} /></TextLink>}
				{person.profile.socialMedia.instagram!==null && person.profile.socialMedia.instagram!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://www.instagram.com/"+person.profile.socialMedia.instagram);}}><FontAwesomeIcon icon={['fab', 'instagram']} /></TextLink>}
				{person.profile.socialMedia.snapchat!==null && person.profile.socialMedia.snapchat!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://www.snapchat.com/add/"+person.profile.socialMedia.snapchat);}}><FontAwesomeIcon icon={['fab', 'snapchat-ghost']} /></TextLink>}
				{person.profile.socialMedia.telegram!==null && person.profile.socialMedia.telegram!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://t.me/"+person.profile.socialMedia.telegram);}}><FontAwesomeIcon icon={['fab', 'telegram-plane']} /></TextLink>}
				{person.profile.socialMedia.twitter!==null && person.profile.socialMedia.twitter!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://twitter.com/"+person.profile.socialMedia.twitter);}}><FontAwesomeIcon icon={['fab', 'twitter']} /></TextLink>}
				{person.profile.socialMedia.tiktok!==null && person.profile.socialMedia.tiktok!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://tiktok.com/@"+person.profile.socialMedia.tiktok.replace('@',''));}}><FontAwesomeIcon icon={['fab', 'tiktok']} /></TextLink>}
				{person.profile.socialMedia.twitch!==null && person.profile.socialMedia.twitch!=='' && <TextLink onClick={(e)=>{e.stopPropagation(); window.open("https://twitch.com/"+person.profile.socialMedia.twitch);}}><FontAwesomeIcon icon={['fab', 'twitch']} /></TextLink>}

				<div className="viewprofilelink"><WideButtonLink onClick={(e)=>{e.stopPropagation(); window.location="/pack/meet/"+person.id;}}><span className="viewprofilelinktext">Meet {person.guest.sceneName}</span></WideButtonLink></div>
			</div>

		</div>
  </Card.Body>
</Card>);
					
					
				})}

						</Container>
</>
    );
}
 
export default Pack;