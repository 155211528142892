import styled from 'styled-components';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

const FormWrapper = styled.form`
  width: 100%;
`;

const FormCard = styled(Card)`
	margin:30px auto;   
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	border-radius:10px; 

	.narrow-body &{ 
		width:50%; 
	} 
	&.no-bottom-margin{
		margin-bottom:0px; 
	} 
	&.full-height{
		height:100%; 
	} 
	&.blue .card-header{
		background-color:#28377d;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.dark-blue .card-header{
		background-color:#2d2d42;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.yellow .card-header{
		background-color:#f0c945;
		color:#000000; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.red .card-header{
		background-color:#b82a34;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.gold .card-header{
		background-color:#b6af2b;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.purple .card-header{
		background-color:#9239a2;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.green .card-header{
		background-color:#0d9348;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
	&.orange .card-header{
		background-color:#cb6a30;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}	
	&.gray .card-header{
		background-color:#6d6d6d;
		color:#FFFFFF; 
		border-top-left-radius:10px; 
		border-top-right-radius:10px; 
	}
`;

const FormLabel = {};


FormLabel.Primary = styled(Form.Label)`
	font-weight:bold; 
`;
FormLabel.Secondary = styled(Form.Label)`
	font-size:13px; 
	font-weight:normal;
	margin-bottom:1px; 
`;
const IconInputLabel = styled(InputGroup.Text)`
	width:40px;  
`;
const StyledInputGroup = styled(InputGroup)`
	margin-bottom:10px; 
`; 
const SaveSpinnerContainer = styled.div`
	text-align:center; 
	margin-bottom:3rem; 
`;

const SubmitButton = styled.input.attrs({
	type:'submit'
})`
	display:inline; 
	background-color:#692C90; 
  border:0px; 
	border-radius: 10px;
	padding: 10px 20px;
  color: white;
  font-weight: 700;
	outline: none;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	&:hover {
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&:focus {
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&.green.inverse{ 
		border:1px solid #0d9348; 
		color:#0d9348;
		background-color:transparent; 
	} 
	&.right{ 
		float:right; 
		clear:left; 
	} 
	&:disabled{ background-color:#999999; box-shadow:none; }
`;
const RegularButton = styled(Button)`
	display:inline; 
	background-color:#692C90; 
  border:0px; 
	border-radius: 10px;
	padding: 10px 20px;
  color: white;
  font-weight: 700;
	outline: none;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	&:hover {
		background-color:#692C90; 
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&:focus {
		background-color:#692C90; 
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&.green.inverse{ 
		border:1px solid #0d9348; 
		color:#0d9348;
		background-color:transparent; 
	} 
	&.green{ 
		background-color:#0d9348; 
	} 
	&.red{ 
		background-color: #b82a34; 
	} 
	&.red.inverse{ 
		border:1px solid #b82a34; 
		color:#b82a34;
		background-color:transparent; 
	} 
	&.gray{ 
		background-color: #6d6d6d; 
	} 
	&.blue{ 
		background-color: #28377d; 
	} 
	&.gray.inverse{ 
		border:1px solid #6d6d6d; 
		color:#6d6d6d;
		background-color:transparent; 
	} 
	&.right{ 
		float:right; 
		clear:left; 
	} 
	&.full-width{ 
		width:100%; 
		margin:10px auto;
	} 
	&.low-key.cancel{ 
		background-color:#FFF;
		color:#28377d; 
		border:0px; 
	} 
	&.low-key.cancel:hover{ 
		background-color:#b82a34; 
		color:#FFF; 
} 
	&:disabled{ background-color:#999999; box-shadow:none; }
`; 
const SaveButton = styled.input.attrs({ 
  type: 'submit',
  value: 'Save Changes'
})`
	display:block; 
	margin:0 auto 3rem auto;
	background-color:#692C90; 
  border:0px; 
	border-radius: 10px;
	padding: .75rem;
  color: white;
  font-weight: 700;
  width: 100%;
  font-size: 1.3rem;
	outline: none;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	box-shadow: 0 3px 6px rgba(40,55,125,0.16), 0 3px 6px rgba(40,55,125,0.23);
	&:hover {
  	box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&:focus {
   box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
  }
	&:disabled{ background-color:#999999; box-shadow:none; }

	.narrow-body &{ 
		width:50%; 
		
	} 
`;
export { FormWrapper, FormCard, FormLabel, IconInputLabel, StyledInputGroup, SaveButton, SaveSpinnerContainer, SubmitButton, RegularButton };