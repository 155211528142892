import React, { useState } from "react";
import axios from 'axios';
import { useAuth } from "../contexts/auth";

import { LoginCard, LoginForm, LoginInput, LoginButton, LoadingSpinner } from "../styled-components/AuthForms.js";

import { Link, Redirect } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

function Login(props) {
	const referer = props.location.state.referer || '/';
	const [isLoggedIn, setLoggedIn] = useState(false);
// 	const [needToPay, setNeedToPay] = useState(false);
	const [signInTryCounter, setSignInTryCounter] = useState(0);
	const [isEnteringData,setIsEnteringData] = useState(false); 
	const [isError, setIsError] = useState(false);
	const [hideForm, setHideForm] = useState(false);
	const [userName, setUserName] = useState("");
	const [password, setPassword] = useState("");
	const { setAuthToken } = useAuth();

	function checkRegStatus(auth){ 
		var regStatus = localStorage.getItem("registrationStatus");
		if(regStatus==='ok'){ 
			setLoggedIn(true);
		} else { 
			axios.get(process.env.REACT_APP_API_HOST+"/registrations/mine",{headers: {'Bearer': auth.token}})
				.then(result => {
				if (result.status === 200) {
					if(result.data.registrationStatus.status === 'paid' || result.data.registrationStatus.status === 'partially-paid'){
						localStorage.setItem("registrationStatus", "ok");
					} else if(result.data.registrationStatus.status === 'initialized'){ 
						localStorage.setItem("registrationStatus", "initialized");
					} 
					setLoggedIn(true);
				} else {
				}
			}).catch(e => {
			});
		}
	} 
	
	function postLogin(e) {
		e.preventDefault();
		setIsEnteringData(false); 
		setSignInTryCounter(signInTryCounter+1); 
		axios.post(process.env.REACT_APP_API_HOST+"/auth/login", {email:userName,password:password})
			.then(result => {
			if (result.status === 200) {
				setAuthToken(result.data);
				setHideForm(true); 
				checkRegStatus(result.data); 
			} else {
				setIsError(true);
			}
		}).catch(e => {
			setIsError(true);
		});
	}

// 	if (needToPay) {
// 		return <Redirect to={{ pathname: "/payments/finish-registration" }} />;
// 	}
	if (isLoggedIn) {
    return <Redirect to={referer} />;
	}
	let errorMessage = (isError && signInTryCounter>2) ? <span>Bark! It's still not working. <br/><Alert.Link to="/reset-password">Need to reset your password?</Alert.Link></span>:'Arf! Could not sign you in. Check the email address or try a different password.';
	if(hideForm){ 
		return <LoadingSpinner>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />
						</LoadingSpinner>; 
	} 
	return (
		<LoginCard>
  <Card.Header as="h5">Sign In</Card.Header>
  <Card.Body>
		{ isError && !isEnteringData && <Alert variant="danger">{errorMessage}</Alert> }
			<LoginForm onSubmit={postLogin}>
			<LoginInput
					type="username"
					value={userName}
					onChange={e => {
						setUserName(e.target.value);
						setIsEnteringData(true); 
					}}
					onFocus={e => {
						setIsEnteringData(true); 
					}}
					placeholder="email"
				/>
				<LoginInput
					type="password"
					value={password}
					onChange={e => {
						setPassword(e.target.value);
					}}
					onFocus={e => {
						setIsEnteringData(true); 
					}}
					placeholder="password"
				/>
		<LoginButton />
			</LoginForm>
		<Link to="/reset-password">Forget your password?</Link>
  </Card.Body>
</LoginCard>
	);
}
export default Login;