import React, { useState }  from "react";


import { RegularButton } from "../../styled-components/Forms.js";
import { Editor } from '@tinymce/tinymce-react';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'



function ThreadReply(props) {
	
	
	const threadId = props.threadId;
	const successCallback = props.onSend; 
	
	const [replyBody, setReplyBody] = useState(''); 
	const [showReplyBox,setShowReplyBox] = useState(false); 
	
	function handleEditorChange(content, editor){ 
		setReplyBody(content); 
	} 
	
	function sendReply(){ 
		
		successCallback(threadId,replyBody); 
		setReplyBody(''); 
		setShowReplyBox(false); 
		
	} 
	
																	
	return (<>
							
			{!showReplyBox && <Row><Col><RegularButton onClick={(e)=>setShowReplyBox(true)}>Reply to this Thread</RegularButton></Col></Row> }
			{showReplyBox && <>
						<Container>
				<Row>
					<Col className="messageReplyEditor">
				
						<h5>Reply to this Thread</h5>
							<Editor
								apiKey='fn70u8o0lfx4eb27syw6okc0qo0zlg2sfmze2m0b99x16kvs'
								value={replyBody}
								init={{
									 height: 120,
									 menubar: false,
									plugins: 'emoticons',
									 toolbar:
										 'bold italic strikethrough | forecolor backcolor emoticons | bullist numlist | removeformat '
								 }}
								 onEditorChange={handleEditorChange}
							 />
					</Col>
			</Row>
					<Row>
						<Col className="messageReplySend">
						<RegularButton onClick={(e)=>sendReply()}>Send Reply</RegularButton>
					</Col>
				</Row>
						</Container></>}
					</>
	);
}
 
export default ThreadReply;