import React, {useState, useEffect} from "react";

import { useMyRegistration } from "../../contexts/myregistration.js";
import { useAuth } from "../../contexts/auth";

import { AddPhotoButton, EditPhotoButton, ProfilePhoto } from "../../styled-components/Profile.js";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/pro-regular-svg-icons'; 
import { faEdit } from '@fortawesome/pro-solid-svg-icons'; 
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'

import ProfilePhotoAdder from './PhotoAdder.js'; 
import ProfilePhotoEditor from './PhotoEditor.js'; 

library.add(
	faCamera,
	faEdit
);

function ProfilePhotosManager(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { authToken } = useAuth();
	const [profilePhotos, setProfilePhotos] = useState([{id:"default",uri:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png",isPrimary:true,caption:""}]); 

	const [isAddingPhoto, setIsAddingPhoto] = useState(false);
	const [isEditingPhoto, setIsEditingPhoto] = useState(false);
	const [selectedPhotoToEdit, setSelectedPhotoToEdit ] = useState(''); 
	const [defaultProfilePhotoForThisUser,setDefaultProfilePhotoForThisUser] = useState(process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png"); 
	
	
	// eslint-disable-next-line
	let defaultProfilePics = {
				pup:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_pup.png",
				dog:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_pup.png",
				cat:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_cat.png",
				pony:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_pony.png",
				'other-critter':process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_other.png",
				handler:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png",
				parent:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png",
				trainer:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png",
				friend:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png",
				other:process.env.REACT_APP_GCS_IMAGE_HOST+"/profile_generic_human.png"
	}
	
  useEffect(() => {
		if(myRegistration.profile && myRegistration.profile.photos){ 
			if(myRegistration.profile.photos.length > 0){ 
				setProfilePhotos(myRegistration.profile.photos); 
			}
		} 
		if(myRegistration.guest){ 
			setDefaultProfilePhotoForThisUser(defaultProfilePics[myRegistration.guest.role]); 
		}
// 		return function cleanup() {
//       setProfilePhotos([]); 
//     };

  }, [myRegistration,defaultProfilePics]) 
			 
	
	
	function finishAddingPhoto(newPhotoData){ 
		myRegistration.profile.photos = newPhotoData; 
		setMyRegistration(myRegistration); 
		setIsAddingPhoto(false);
		setIsEditingPhoto(false);
	}
	function cancelAddingPhoto(){
		setIsAddingPhoto(false);
		setIsEditingPhoto(false);
	}
	
	const initialLoading = <div id="LoadingPhotos"><Spinner animation="grow" variant="secondary" /></div>;
	
	const noPhotosInProfile = <Container><div id="AddFirstProfilePhoto">
			<Row>
				<Col md={6} sm={10}>
					<img src={defaultProfilePhotoForThisUser} alt="" className="profilephoto" width="100%" />	
				</Col>
				<Col>
					<p>You don't have any profile photos yet. So mysterious!</p>
					<AddPhotoButton onClick={()=>{ setIsAddingPhoto(true); }}><FontAwesomeIcon icon={['far', 'camera']} size="lg" /> Add Photo</AddPhotoButton>
				</Col>
			</Row>
		</div></Container>;

	const photosDisplay = <div id="DisplayProfilePhotos">
					{ profilePhotos.map((photo) => <ProfilePhoto key={photo.id} image={process.env.REACT_APP_API_HOST+photo.uri+'?size=medium&bearer='+authToken.token}>
																				 <EditPhotoButton onClick={()=>{ setIsEditingPhoto(true); setSelectedPhotoToEdit(photo); }} ><FontAwesomeIcon icon={['fas', 'edit']} />Edit</EditPhotoButton>
																				 </ProfilePhoto>)}
					<AddPhotoButton onClick={()=>{ setIsAddingPhoto(true); }}><FontAwesomeIcon icon={['far', 'camera']} size="lg" /> Add Photo</AddPhotoButton>
		</div>; 


	
	return (<>
					{ !isAddingPhoto && !isEditingPhoto && !myRegistration.profile && initialLoading }
					{ !isAddingPhoto && !isEditingPhoto && myRegistration.profile && myRegistration.profile.photos && myRegistration.profile.photos.length===0 && noPhotosInProfile }
					{ !isAddingPhoto && !isEditingPhoto && myRegistration.profile && myRegistration.profile.photos && myRegistration.profile.photos.length>0 && photosDisplay }
					{ isAddingPhoto && <ProfilePhotoAdder makePrimary={(myRegistration.profile && myRegistration.profile.photos && myRegistration.profile.photos.length===0)} onSave={finishAddingPhoto} onCancel={cancelAddingPhoto} /> }
					{ isEditingPhoto && <ProfilePhotoEditor photo={selectedPhotoToEdit} onSave={finishAddingPhoto} onCancel={cancelAddingPhoto} /> }

</>
    );
}
 
export default ProfilePhotosManager;