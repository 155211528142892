import React, { useState,useEffect }  from "react";
import axios from 'axios';
import { useAuth } from "../contexts/auth";
import { useMyRegistration } from "../contexts/myregistration.js";
import { useEventSettings } from "../contexts/eventsettings.js";
import styled from 'styled-components';

import { LoadingSpinner } from "../styled-components/AuthForms.js";
// import { TextLink,WideButtonLink } from "../styled-components/SmallElements.js";
import { FormCard,RegularButton } from "../styled-components/Forms.js";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'

import './Dashboard.css';

import logo from "../assets/PITMMiniLogo.png"; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
} from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faShareAlt,
	faDog,
	faCat,
	faHorse,
	faHeart,
	faSmile,
	faPaw,
	faAmbulance
} from '@fortawesome/free-solid-svg-icons';
import {
  faImage
} from '@fortawesome/free-regular-svg-icons';
import {
  faPlaneAlt,
	faHomeAlt
} from '@fortawesome/pro-solid-svg-icons';
	
library.add(
	faDog,
	faCat,
	faHorse,
	faHeart,
	faSmile,
	faPaw,
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
	faEdit,
	faShareAlt,
	faImage,
	faAmbulance,
	faPlaneAlt,
	faHomeAlt
);

const InviteLine = styled.div`
	width:100%; 
	border:1px solid #EEEEEE; 
	border-radius:10px; 
	position:relative; 
	margin-right:0px; 
	margin-left:0px; 
	margin-bottom:10px; 
	padding:10px; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	& p{ 
		margin-bottom:0px; 
	} 
	&.declined{ 
		box-shadow: 0 1px 2px rgba(0,0,0,0.16), 0 1px 2px rgba(0,0,0,0.23);
	}
	&.declined p{ 
		color:#999; 
	} 
	&.declined p b{ 
		font-weight:normal; 
	}
	&.declined p b::after{ 
		content: " (Declined)";
	}

`; 

const AcceptButton = styled(RegularButton)`
	padding: 5px 10px;
	background-color:#0d9348; 
	margin:0px 3px 5px; 
	&:hover{ 
		background-color:#0d9348; 
	}
`; 
const DeclineButton = styled(RegularButton)`
	padding: 5px 10px;
	border:1px solid #b82a34; 
	color:#b82a34;
	background-color:transparent; 
	margin:0px 3px 5px; 
	&:hover{ 
		color:#FFFFFF; 
		background-color:#b82a34; 
		border-color:#b82a34;
	} 
	.declined &{ 
		visibility:hidden;
	}
`; 


function Dashboard(props) {
// 	const myRegistration = useMyRegistration()[0]; ){
// 	const { myRegistration, setMyRegistration } = useMyRegistration(); 
	const regContext = useMyRegistration(); 
	const myRegistration = regContext.myRegistration; 
  const { eventSettings } = useEventSettings();
  const { authToken, setAuthToken } = useAuth();
	
	const [ dataLoaded,setDataLoaded ] = useState(false); 
 	const [ pronounTranslations,setPronounTranslations ] = useState(); 
 	const [ roleTranslations,setRoleTranslations ] = useState({
  "pup": "Pup / Puppy",
  "dog": "Dog",
  "cat": "Cat / Kitty",
  "pony": "Pony",
  "other-critter": "Critter",
  "handler": "Handler",
  "parent": "Daddy / Mommy",
  "trainer": "Trainer",
  "friend": "Friend",
  "other": "Friend"
}); 
	const [ totalRegistrations,setTotalRegistrations ] = useState([]); 
	const [ recentRegistrations,setRecentRegistrations ] = useState([]); 
	const [ profilePrompts,setProfilePrompts ] = useState([]); 
	const [ housingPrompts,setHousingPrompts ] = useState([]); 
	const [ unreadMessages,setUnreadMessages ] = useState([]); 
	
	const [pileAcceptDeclineButtonSpinners,setPileAcceptDeclineButtonSpinners] = useState({}); 
	
	let defaultProfilePics = {
			pup:"/profile_generic_pup.png",
			dog:"/profile_generic_pup.png",
			cat:"/profile_generic_cat.png",
			pony:"/profile_generic_pony.png",
			'other-critter':"/profile_generic_other.png",
			handler:"/profile_generic_human.png",
			parent:"/profile_generic_human.png",
			trainer:"/profile_generic_human.png",
			friend:"/profile_generic_human.png",
			other:"/profile_generic_human.png"
}

	function getDashboardData(){ 
		
		axios.get(process.env.REACT_APP_API_HOST+"/dashboard",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										
										setTotalRegistrations(result.data.registrations.total); 
										setRecentRegistrations(result.data.registrations.recent); 
										setProfilePrompts(result.data.profilePrompts); 
										setHousingPrompts(result.data.housingPrompts); 
										setUnreadMessages(result.data.unreadMessages); 
										

										var acceptButtons = {}; 
										for(var i in result.data.housingPrompts.puppyPileInvites){ 
											acceptButtons[result.data.housingPrompts.puppyPileInvites[i].id] = false; 
										}
										setPileAcceptDeclineButtonSpinners(acceptButtons); 

										setDataLoaded(true); 
										 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
										
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	useEffect(()=>{
		
		getDashboardData(); 
		
	// eslint-disable-next-line
	},[]); 
	
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									result.data["guest.role"].other="Friend"; 
									result.data["guest.role"].friend="Friend"; 
									result.data["guest.role"]["other-critter"]="Critter"; 
									
									setRoleTranslations(result.data["guest.role"]); 
									
									setPronounTranslations(result.data["guest.pronouns"]); 
								} else {
								}
							}).catch(e => {
							});
	// eslint-disable-next-line
	},[]); 
	
	
	function declinePile(pileId,registrationId){ 
		
		var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
		cloneAcceptSpinners[pileId] = true; 
		setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
		
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+pileId+"/members/"+registrationId,{status:'declined'},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
											getDashboardData(); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	function acceptPile(pileId,registrationId){ 
		
		var cloneAcceptSpinners = {...pileAcceptDeclineButtonSpinners}; 
		cloneAcceptSpinners[pileId] = true; 
		setPileAcceptDeclineButtonSpinners(cloneAcceptSpinners); 
		
		axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+pileId+"/members/"+registrationId,{status:'accepted'},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
											getDashboardData(); 
									} else {
									}
								}).catch(e => {
								});	
		
	} 
	
	function formatTimeStamp(strDate){ 
		var date = new Date(strDate); 
		var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
		var suffix = 'th'; 
		var day = date.getDate(); 
		if(day===1 || day === 21 || day === 31) suffix = 'st'; 
		if(day===2 || day === 22) suffix = 'nd'; 
		if(day===3 || day === 23) suffix = 'rd'; 
		var string = months[date.getMonth()]+' '+date.getDate()+suffix+' @ '+date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'}); 
		return string; 
	} 
	
    return (<>
						<Container>
				
				{!dataLoaded && <>
						<h1>Loading Dashboard...</h1>
							<LoadingSpinner>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />
						</LoadingSpinner></>}
				
				{dataLoaded && <>
			
						<h1>{totalRegistrations} people are coming to PITM!</h1>
					<Row>
						<Col sm={12} md={6}>
							
								<FormCard id="" className="blue">
									<Card.Header as="h5">Recently Joined the Pack</Card.Header>
									<Card.Body>
										
				{recentRegistrations.map((person)=>{ 
											
											
												
					var photo; 
					if(person.profile.photos.length>0){ 
						for(var i in person.profile.photos){ 
							if(person.profile.photos[i].isPrimary){ 
								photo = process.env.REACT_APP_API_HOST+person.profile.photos[i].uri+'?size=small&bearer='+authToken.token;
							} 
						} 
					} 
					if(photo===undefined){ 
						photo = process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[person.guest.role];
					} 
					
													
													
											return(
										<Row className="recentlyJoinedPerson" key={person.id} onClick={()=>{window.location.href="/pack/meet/"+person.id;}}>
											<Col xs={3} sm={2} md={3} lg={2} className="profilepic">
												<img src={photo} width="70" alt={person.guest.sceneName} />
											</Col>
											<Col xs={9} sm={10} md={9} lg={10} className="profiledetails">
												<h6>{person.guest.sceneName}</h6>
												<div>
													<span>
													{ (person.guest.role==='pup' || person.guest.role==='dog') && <FontAwesomeIcon icon="dog" />}
													{ (person.guest.role==='cat') && <FontAwesomeIcon icon="cat" />}
													{ (person.guest.role==='pony') && <FontAwesomeIcon icon="horse" />}
													{ (person.guest.role==='other-critter') && <FontAwesomeIcon icon="paw" />}
													{ (person.guest.role==='handler' || person.guest.role==='trainer' || person.guest.role==='parent') && <FontAwesomeIcon icon="heart" />}
													{ (person.guest.role==='friend' || person.guest.role==='other') && <FontAwesomeIcon icon="smile" />}

														{roleTranslations[person.guest.role]}</span>
													<span><FontAwesomeIcon icon={['far', 'comment-smile']} /> {pronounTranslations[person.guest.pronouns]}</span>
												</div>
											</Col>
										</Row>); 
										})}
										
									</Card.Body>
								</FormCard>
								
						</Col>
						<Col sm={12} md={6}>

							{ unreadMessages.length>0 && 
								<FormCard id="" className="green profilePrompts">
									<Card.Header as="h5">New Messages</Card.Header>
									<Card.Body>
										{ unreadMessages.map((message)=>{
													var tofrom; 
													var profilePicURL; 
													if(message.fromDirectors===false){
														tofrom = <>{message.from.name}</>
														profilePicURL= (message.from.profilePic)?process.env.REACT_APP_API_HOST+message.from.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[message.from.role];
													} else { 
														tofrom = "PITM Directors"; 
													} 
											
											return(
												
											<Row key={message.id} className="unreadMessage">
												<Col className="icon" xs={2} sm={1} md={2} lg={1}>{(message.fromDirectors) && <img src={logo} alt="directors" width="40" height="40" className="directors" />}{ profilePicURL && <img src={profilePicURL} alt={tofrom} width="40" height="40" className="guest" />}</Col>
												<Col xs={9} sm={8} md={6} lg={8}>
													<Row className="envelope">
														<Col>
															From {tofrom} on {formatTimeStamp(message.timestamp)}
														</Col>
													</Row>
													<Row className="subject">
														<Col>
															{message.subject}
														</Col>
													</Row>
												</Col>
												<Col sm={3} md={4} lg={3} className="openButton">
													<RegularButton onClick={()=>{window.location='/messages/'+message.threadId}}>Open</RegularButton>
												</Col>
											</Row>

												
												
												); 
										})}
										
									</Card.Body>
								</FormCard>
								}
								
							{ (profilePrompts.publicProfile!==undefined || profilePrompts.personalDetails!==undefined || profilePrompts.travelInfo!==undefined || housingPrompts.chooseHousingPreference!==undefined ) &&
								<FormCard id="" className="red profilePrompts">
									<Card.Header as="h5">Finish Your Profile</Card.Header>
									<Card.Body>
										{profilePrompts.publicProfile!==undefined && profilePrompts.publicProfile.includes('add-bio') && 
										<Row className="prompt bio">
											<Col>
												<span><FontAwesomeIcon icon="edit" size="2x"/></span> <span><a href="/profile">Add your bio</a> to your profile so we can all get to know you!</span>
											</Col>
										</Row>
											}

										{profilePrompts.publicProfile!==undefined && profilePrompts.publicProfile.includes('add-socials') && 
										<Row className="prompt socialmedia">
											<Col>
												<span><FontAwesomeIcon icon="share-alt" size="2x"/></span> <span><a href="/profile">Add your social media accounts</a> to your profile to connect with each other before and after the event!</span>
											</Col>
										</Row>
											}
										
										{profilePrompts.publicProfile!==undefined && profilePrompts.publicProfile.includes('photos') && 
										<Row className="prompt photos">
											<Col>
												<span><FontAwesomeIcon icon={['far', 'image']} size="2x"/></span> <span><a href="/profile">Add some photos</a> to your profile!</span>
											</Col>
										</Row>
											}
										
										{profilePrompts.personalDetails!==undefined && profilePrompts.personalDetails.includes('add-er-contact') && 
										<Row className="prompt ercontact">
											<Col >
												<span><FontAwesomeIcon icon={['fas', 'ambulance']} size="2x"/></span> <span>We need you to add your <a href="/profile/private">Emergency Contact</a> to your profile. </span>
											</Col>
										</Row>
											}
										{profilePrompts.travelInfo!==undefined && profilePrompts.travelInfo.includes('add-travel') && 
										<Row className="prompt travel">
											<Col>
												<span><FontAwesomeIcon icon={['fas', 'plane-alt']} size="2x"/></span> <span>Please <a href="/profile/travel">add your travel plans</a> to your profile so we know when to expect you.</span>
											</Col>
										</Row> 
											}
										{housingPrompts.chooseHousingPreference!==undefined && housingPrompts.chooseHousingPreference===true && 
										<Row className="prompt housingpref">
											<Col>
												<span><FontAwesomeIcon icon="home-alt" size="2x"/></span> <span><a href="/puppypile">Set your housing preference</a> to choose between a cabin and a yurt.</span>
											</Col>
										</Row> 
											}
									</Card.Body>
								</FormCard>
								}
								
							
								{ (housingPrompts.inAPile===false || (housingPrompts.puppyPileInvites!==undefined && housingPrompts.puppyPileInvites.length>0)) && 
								<FormCard id="" className="purple profilePrompts">
									<Card.Header as="h5">Puppy Pile Invites</Card.Header>
									<Card.Body>
										{ housingPrompts.inAPile===false && (housingPrompts.puppyPileInvites===undefined || housingPrompts.puppyPileInvites.length===0) &&
										<Row>
											<Col>A Puppy Pile is a group of people who want to share a cabin or yurt together. You can create a new Puppy Pile or ask one of your friends to invite you to theirs. 
												Learn more on the <a href="/puppypile">My Puppy Pile</a> page.</Col>
										</Row>
										}
											
										{ housingPrompts.inAPile===false && housingPrompts.puppyPileInvites!==undefined && housingPrompts.puppyPileInvites.length>0 &&
										<Row>
											<Col className="pileInvitePreamble">You've been invited to join {housingPrompts.puppyPileInvites.length===1 ? 'a':'these' } Puppy Pile{housingPrompts.puppyPileInvites.length===1 ? '':'s' }! You can only be in one pile, but you can leave, switch, or create your own at any time.</Col>
										</Row>
										}										
										{ housingPrompts.inAPile===true && housingPrompts.isPileAlpha===false  && housingPrompts.puppyPileInvites!==undefined && housingPrompts.puppyPileInvites.length>0 &&
										<Row>
											<Col className="pileInvitePreamble">You've been invited to join {housingPrompts.puppyPileInvites.length===1 ? 'a':'these' } Puppy Pile{housingPrompts.puppyPileInvites.length===1 ? '':'s' }! You are already in a pile, so if you accept one of these you'll be removed from it.</Col>
										</Row>
										}				
										{ housingPrompts.inAPile===true && housingPrompts.isPileAlpha===true  && housingPrompts.puppyPileInvites!==undefined && housingPrompts.puppyPileInvites.length>0 &&
										<Row>
											<Col>You've been invited to join {housingPrompts.puppyPileInvites.length===1 ? 'a':'other' } Puppy Pile{housingPrompts.puppyPileInvites.length===1 ? '':'s' }. Since you are the Alpha of your current Puppy Pile, you'll need to delete the current pile or designate a new Alpha. You can make these changes on the <a href="/puppypile">My Puppy Pile</a> page.</Col>
										</Row>
										}										
										
										
											{housingPrompts.isPileAlpha===false  && housingPrompts.puppyPileInvites!==undefined && housingPrompts.puppyPileInvites.length>0 && housingPrompts.puppyPileInvites.map((pile) => { 
// 											var thisRef = React.createRef();
																										 var myStatusInPile = ''; 
// 																										 for(var i in pile.members){ 
// 																											 if(pile.members[i].registrationId == myRegistration.id){ 
// 																												 if(pile.members[i].status=='accepted' || pile.members[i].status=='declined') myStatusInPile = pile.members[i].status;  
// 																											 } 
// 																										 } 
																										 return (
																<InviteLine key={pile.id} className={ "puppyPileInvitationLine " + myStatusInPile }>
				 													<Row>
																		<Col sm={7}>
																			<p><b>{pile.name}</b></p>
																		</Col>
																		<Col sm={5}>
																			{pileAcceptDeclineButtonSpinners[pile.id] ? 
																				<Spinner animation="grow" variant="success" />
																				:
																				<>
																					<AcceptButton onClick={(e)=>{acceptPile(pile.id,myRegistration.id)}}>Accept</AcceptButton>
																					<DeclineButton className="declinebutton" onClick={()=>{declinePile(pile.id,myRegistration.id)}}>Decline</DeclineButton>																			
																				</>}
																		</Col>
																	</Row>
			 													</InviteLine>)}
																) }
										
										
										
										
									</Card.Body>
								</FormCard>
								}
								
						</Col>
					</Row>
						
			
			
				</>}
					

						</Container>
</>
    );
}
 
export default Dashboard;