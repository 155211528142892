import React from "react";

// images
import headerimg from "../assets/LoggedOutMastImg.png"; 


import './Mast.css'; 

function LoginPageHeader(props) {
	
    return (
<div id="Mast">
	<img src={headerimg} alt="" className="frontimg" />
	<h1>Welcome To Puppies In The Mountains!</h1>
</div>
    );
}
 
export default LoginPageHeader;