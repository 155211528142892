import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useAuth } from "../../contexts/auth";


import { FormLabel } from "../../styled-components/Forms.js";
import { AddPhotoButton, DeletePhotoButton, ProfilePhoto } from "../../styled-components/Profile.js";

import Switch from '../../custom-widgets/Switch.js'; 

import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'
import Fade from 'react-bootstrap/Fade'

function ProfilePhotoEditor(props) {
	const { myRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
	const [show,setShow] = useState(false); 
	const [photo] = useState(props.photo); 
	const [photoCaption,setPhotoCaption] = useState(props.photo.caption); 
	const [photoIsPrimary,setPhotoIsPrimary] = useState(props.photo.isPrimary); 
	const [saveActionInProgress,setSaveActionInProgress] = useState(false); 
	const [deleteActionInProgress,setDeleteActionInProgress] = useState(false); 
	
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const handleCloseDeleteConfirmation = () => setShowDeleteConfirmation(false);
  const handleShowDeleteConfirmation = () => setShowDeleteConfirmation(true);

	const onSave = props.onSave; 
	const onCancel = props.onCancel; 
	
	
	useEffect(() => {
		setShow(true); 
	},[]); 
	
	function doPhotoUpdate(){ 
		setSaveActionInProgress(true); 
		var sendData = {caption:photoCaption,
									 	isPrimary:photoIsPrimary}; 
		axios.put(process.env.REACT_APP_API_HOST+"/registrations/"+myRegistration.id+"/photos/"+photo.id,sendData,{headers:{'Bearer':authToken.token}})
				.then(result => {
								if (result.status === 200) {
									setTimeout(()=>{
										onSave(result.data); 
										setSaveActionInProgress(false); 
									},500); 
									if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
								} else {
								}
							}).catch(e => {
							});
	} 
	
	function doPhotoDelete(){ 
		setDeleteActionInProgress(true)
		handleCloseDeleteConfirmation(); 
		axios.delete(process.env.REACT_APP_API_HOST+"/registrations/"+myRegistration.id+"/photos/"+photo.id,{headers:{'Bearer':authToken.token}})
				.then(result => {
								if (result.status === 200) {
									setTimeout(()=>{
										onSave(result.data); 
										setDeleteActionInProgress(false); 
										},500); 
									if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
								} else {
								}
							}).catch(e => {
							});
		
	} 
	
  return (<><Fade in={show}>
								<Container>
									<Form.Group as={Row}>
										<Col sm={12} md={3} className="editPhotoShowPhoto">
											<ProfilePhoto image={process.env.REACT_APP_API_HOST+photo.uri+'?size=medium&bearer='+authToken.token} />						
										</Col>
								 		<Col>
											<FormLabel.Primary>Caption</FormLabel.Primary>
									    <Form.Control as="textarea" rows="3" value={photoCaption} onChange={(e)=>{setPhotoCaption(e.target.value)}} />
										</Col>
									</Form.Group>
									<Form.Group as={Row}>
											<Switch isOn={photoIsPrimary} onColor="#0d9948" handleToggle={() => {setPhotoIsPrimary(!photoIsPrimary); }} id="profile_editphotoasprimary" />
											<Form.Label htmlFor="profile_editphotoasprimary">Make This My Primary Photo</Form.Label>
									</Form.Group>
									<Row>
										<Col sm={8}>
											{saveActionInProgress && <Spinner animation="grow" variant="success" /> }
											{!saveActionInProgress && <>
												<AddPhotoButton onClick={doPhotoUpdate}>Save</AddPhotoButton>
												<AddPhotoButton className="inverse" onClick={onCancel}>Cancel</AddPhotoButton>
											 	</>}
										</Col>
										<Col sm={4} className="col-align-right">
											{deleteActionInProgress && <Spinner animation="grow" variant="danger" /> }
											{!deleteActionInProgress && <DeletePhotoButton onClick={handleShowDeleteConfirmation}>Delete</DeletePhotoButton> }
										</Col>
									</Row>
									</Container>
								</Fade>
					
      <Modal show={showDeleteConfirmation} onHide={handleCloseDeleteConfirmation}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this photo? This cannot be undone.</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteConfirmation}>
            Cancel
          </Button>
          <DeletePhotoButton onClick={doPhotoDelete}>
            Delete It
          </DeletePhotoButton>
        </Modal.Footer>
      </Modal>
</>
    );
}
 
export default ProfilePhotoEditor;