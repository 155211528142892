import React, { useState }  from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import { useEventSettings } from "../../contexts/eventsettings";
import { useMyRegistration } from "../../contexts/myregistration.js";
import styled from 'styled-components';
import CreditCardForm from './CreditCardForm.js';

import { addMonths, lastDayOfMonth, durationInMonths } from '@progress/kendo-date-math';

import { FormWrapper, FormCard, FormLabel, IconInputLabel, StyledInputGroup,SubmitButton } from "../../styled-components/Forms.js";

import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';


import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Alert from 'react-bootstrap/Alert';


const PaymentPlanLine = styled(Row)`
	width:100%; 
	padding-top:3px; 
	padding-bottom:4px; 
	margin-top:2px; 
	margin-bottom:2px;
	border-bottom:1px solid #DEDEDE; 

	& .amount{ 
		text-align:right;
	}
`; 

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

function PaymentsSetup(props) {
// 	const [myRegistration] = useState({});
	const { myRegistration } = useMyRegistration(); 
  const { authToken, setAuthToken } = useAuth();
  const { eventSettings } = useEventSettings();

	const [guestPrice] = useState(325); //TODO - shoudl this be preset?
	const [amountToPayToday, setAmountToPayToday] = useState('');
	const [balanceToPay, setBalanceToPay] = useState(0);
	const [monthlyInstallmentAmount, setMonthlyInstallmentAmount] = useState(0);
	const [minimumMonthlyPayment, setMinimumMonthlyPayment] = useState('');
	const [paymentPlan, setPaymentPlan] = useState([]);
	const [timeOfMonth, setTimeOfMonth] = useState('beginning');
	const [showMinimumError, setShowMinimumError] = useState(false);
	const [showPaymentPlanCreator, setShowPaymentPlanCreator] = useState(false);
	const [showPaymentPlanOutput, setShowPaymentPlanOutput] = useState(false);
	const [showMonthlyInstallmentMinimumError, setShowMonthlyInstallmentMinimumError] = useState(false);
	const [showPaymentPanel, setShowPaymentPanel] = useState(false);
	const [schedulesCreated, setSchedulesCreated] = useState(false);
	const [paymentComplete, setPaymentComplete] = useState(false);

	let today = new Date(); 
	let paymentPlanCutOff = addMonths(eventSettings.paymentsDueDate,-1); 
	const paymentPlansAllowed = today<paymentPlanCutOff; 
	
	function resetFlow(){ 
			setShowPaymentPlanCreator(false); 
			setShowMinimumError(false); 
			setShowMonthlyInstallmentMinimumError(false); 
			setBalanceToPay(0); 
			setMinimumMonthlyPayment(0); 
			setMonthlyInstallmentAmount(0); 
			setPaymentPlan({}); 
			setShowPaymentPlanOutput(false); 
			setShowPaymentPanel(false); 
	} 
	function resetPlanFlow(){ 
			setPaymentPlan({}); 
			setShowPaymentPlanOutput(false); 
	} 
	function setPaymentAmount(amount){ 
		setAmountToPayToday(amount); 
	} 
	
	
	function processTodayAmount(e){ 
		
		e.preventDefault();
		var amountval = parseFloat(amountToPayToday);
		if(isNaN(amountval)){ setAmountToPayToday(''); return false; }
		if(amountval>guestPrice) amountval = guestPrice;
		if(amountval<50){ setShowMinimumError(true); return false; } 
		else { setShowMinimumError(false); } 
		setAmountToPayToday(amountval); 

		
		if(paymentPlansAllowed && amountval<guestPrice && !showMinimumError){ 
			var balance = myRegistration.registrationStatus.guestPrice-amountval; 
			var months = durationInMonths(new Date(), eventSettings.paymentsDueDate); // Returns the duration in months, `9`.
			if(months<1) months = 1; 

			var minimumMonthly = Math.ceil(balance/months); 
			
			setShowPaymentPlanCreator(true); 
			setBalanceToPay(balance); 
			setMinimumMonthlyPayment(minimumMonthly); 
			setShowMinimumError(false); 

		} else if(!showMinimumError) { 
			setShowPaymentPanel(true); 
		} 
	} 
	
	
	function processPaymentPlanSettings(e){ 
		
		e.preventDefault();
		
		var amountval = parseFloat(monthlyInstallmentAmount);
		if(isNaN(amountval)){ setMonthlyInstallmentAmount(''); return false; }
		if(amountval<minimumMonthlyPayment){ 
			setShowMonthlyInstallmentMinimumError(true); 
			return false; 
		} else { 
			setShowMonthlyInstallmentMinimumError(false); 
		} 
		
		var paymentPlanInstallments = []; 
		var thisInstallment = {}; 
		var numMonths = Math.floor(balanceToPay / monthlyInstallmentAmount); 
		var extraMonth = ((balanceToPay - (numMonths*monthlyInstallmentAmount))>0); 
		
		
		var firstPaymentDate = addMonths(new Date(),1); 
		if(timeOfMonth==='end'){ 
			firstPaymentDate = lastDayOfMonth(firstPaymentDate); 
		} else if(timeOfMonth==='beginning') { 
			firstPaymentDate.setDate(1); 
		} else if(timeOfMonth==='middle'){ 
			firstPaymentDate.setDate(15); 
		} 

		for(var i=0;i<numMonths;i++){ 
			thisInstallment = {};
			thisInstallment.amount = monthlyInstallmentAmount; 
			if(timeOfMonth==='end'){ 
				thisInstallment.scheduledDate = lastDayOfMonth(addMonths(firstPaymentDate,i)); 
			} else { 
				thisInstallment.scheduledDate = addMonths(firstPaymentDate,i); 
			}
			paymentPlanInstallments.push(thisInstallment); 
		} 
		
		if(extraMonth){ 
			thisInstallment = {};
			thisInstallment.amount = (balanceToPay - (numMonths*monthlyInstallmentAmount)); 
			if(timeOfMonth==='end'){ 
				thisInstallment.scheduledDate = lastDayOfMonth(addMonths(firstPaymentDate,i)); 
			} else { 
				thisInstallment.scheduledDate = addMonths(firstPaymentDate,i); 
			}
			paymentPlanInstallments.push(thisInstallment); 
		} 
		
		setPaymentPlan(paymentPlanInstallments)
		setShowPaymentPlanOutput(true); 
		
	}
	
	
	
	
	function startPaymentFlow(){ 
		setShowPaymentPanel(true); 
	} 
	function finishPayment(paymentId){ 
		
		localStorage.setItem("registrationStatus", "ok");
		if(paymentPlan.length>0){ 
		axios.post(process.env.REACT_APP_API_HOST+"/registrations/mine/payments/schedules",{schedules:paymentPlan},{headers: {'Bearer': authToken.token}})
      		.then(result => {
									if (result.status === 200) {
										setSchedulesCreated(true); 
										setPaymentComplete(true); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});
		} else { 
			setSchedulesCreated(false); 
			setPaymentComplete(true); 
		} 
	} 
	
	function datePrinter(date){ 
		var months = ["January","February","March","April","May","June","July","August","September","October","November","December"]; 
		var string = months[date.getMonth()]+' '+date.getDate()+', '+date.getFullYear(); 
		return string; 
	} 

	const refInterface = React.createRef();
	
	const thankYou = <p>Thank you for making a payment of ${amountToPayToday}! You now have access to the rest of the My Experience app and will show up 
									as a registered guest. Get to know the rest of the pack, update your profile, and keep your registration details up to date.</p>;
	
	let intro; 
	if(paymentComplete && schedulesCreated){ 
		intro = <>
							{thankYou} 
							<p>You created payment reminders that will be sent { (timeOfMonth==='beginning')?'at the beginning of the month':((timeOfMonth==='middle')?'in the middle of the month':'at the end of the month')} until you finish paying. 
									We will <b>not</b> automatically charge your card. Return to My Experience to make a payment. You can pay any amount at any time as long as you finish paying by September 1st.</p>
						</>
	} else if( paymentComplete && !schedulesCreated){ 
		intro = thankYou; 
	} else if( !paymentComplete){ 
		if(paymentPlansAllowed){ 
			intro = <p>We need to finish your registration by paying your ${guestPrice} registration fee or setting up a payment plan. 
												A payment plan let's you pay in installments between now and the event. Simply start with at least $50 today, and 
												finish paying by September 15th.</p>; 
		} else { 
			intro = <p>We need to finish your registration by paying your ${guestPrice} registration fee. It is too close to the event to start a payment plan, 
												so we'll need to collect the full amount in order to confirm your registration.</p>; 
		}
	} 
		
		
	return (<>
						<Container>
							<h1>Setup Payments</h1>
							<Row>
								<Col>
									
									<FormCard id="" className="orange">
										<Card.Header as="h5">Registration Status</Card.Header>
										<Card.Body> 
											{intro}
										</Card.Body>
									</FormCard>
								</Col>
							</Row>
							{ !paymentComplete && 
							<Row ref={refInterface}>
								<Col lg={(showPaymentPanel && showPaymentPlanCreator)?3:((showPaymentPlanCreator || (!showPaymentPlanCreator && showPaymentPanel))?6:12)} className={(!showPaymentPlanCreator && !showPaymentPanel) ? 'narrow-body':''}>
								
									<FormCard id="" className={(!showPaymentPlanCreator && !showPaymentPanel) ? 'green':'gray'}>
										<Card.Header as="h5">Payments</Card.Header>
										<Card.Body>
											<FormWrapper onSubmit={processTodayAmount}>
												<Form.Row>
													<Form.Group as={Col}>
														{ paymentPlansAllowed && <FormLabel.Primary>How much would you like to pay today?</FormLabel.Primary>}
														{ !paymentPlansAllowed && <FormLabel.Primary>Please pay this amount today:</FormLabel.Primary>}
														<StyledInputGroup>
															<InputGroup.Prepend>
																<IconInputLabel id="basic-addon7">$</IconInputLabel>
															</InputGroup.Prepend>
															<Form.Control id="paytoday" className="" value={amountToPayToday} onFocus={resetFlow} disabled={!paymentPlansAllowed} onChange={(e)=>{ setPaymentAmount(e.target.value); }} placeholder="" />
														</StyledInputGroup>
													</Form.Group>
												</Form.Row>
												{showMinimumError && <Row><Col><Alert variant="danger">You must pay at least $50 today.</Alert></Col></Row> }
												{ !showPaymentPlanCreator && <SubmitButton value="Next" className="right"/> }
											</FormWrapper>
										</Card.Body>
									</FormCard>
								</Col>
									{ showPaymentPlanCreator && 
								<Col lg={(showPaymentPanel)?4:6}>
									<FormCard id="" className={!showPaymentPanel ? 'green':'gray'}>
										<Card.Header as="h5">Set Up Reminders</Card.Header>
										<Card.Body>
											{!showPaymentPlanOutput && <>
											<p>You'll owe ${balanceToPay}. We'll set up payment reminders to help keep you on track. These are just reminders, we will not automatically 
											charge your payment method and you can pay any amount at any time, as long as you pay in full by September 15th.</p>
											<FormWrapper onSubmit={processPaymentPlanSettings}>
												<Form.Row>
													<Form.Group as={Col}>
														<FormLabel.Primary>How much would you like to pay each month?</FormLabel.Primary>
														<StyledInputGroup>
															<InputGroup.Prepend>
																<IconInputLabel id="basic-addon7">$</IconInputLabel>
															</InputGroup.Prepend>
															<Form.Control id="monthlyInstallmentAmount" className="" value={monthlyInstallmentAmount} onChange={(e)=>{ setMonthlyInstallmentAmount(e.target.value); }} placeholder="" />
															<Form.Text id="monthlyinstallmentHelpBlock" muted>Minimum monthly payment of ${minimumMonthlyPayment} (the amount due divided by the number of months before Sept 1st).</Form.Text>
														</StyledInputGroup>
													</Form.Group>
												</Form.Row>
												{showMonthlyInstallmentMinimumError && <Row><Col><Alert variant="danger">Minimum installment amount is ${minimumMonthlyPayment}.</Alert></Col></Row> }
												<Form.Row>
													<Form.Group as={Col}>
														<FormLabel.Primary>When is typically the best time to pay?</FormLabel.Primary>
														<Form.Control id="timeOfMonth" as="select" value={timeOfMonth} onChange={e => { setTimeOfMonth(e.target.value) }}>
															<option value="beginning">Beginning of the month</option>
															<option value="middle">Middle of the month</option>
															<option value="end">End of the month</option>
														</Form.Control>
													</Form.Group>
												</Form.Row>
												<SubmitButton value="Next" className="right"/>
											</FormWrapper> 
											</>
											}
											
											{ showPaymentPlanOutput && 
												<>
												<Form.Row>
													<Form.Group as={Col}>
														<FormLabel.Primary>Here is when we'll remind you to make a payment:</FormLabel.Primary>
														{paymentPlan.map((p)=> <PaymentPlanLine key={p.scheduledDate.getFullYear()+p.scheduledDate.getMonth()}>
																											<Col sm={4} className="amount">${p.amount}</Col>
																											<Col sm={8} className="date">{datePrinter(p.scheduledDate)}</Col>
																										</PaymentPlanLine>)
														
														}
													</Form.Group>
												</Form.Row>
												{!showPaymentPanel &&
												<Form.Row>
													<Col><SubmitButton value="Change" className="green inverse" onClick={resetPlanFlow}/></Col>
													<Col><SubmitButton value={"Confirm & Pay $"+amountToPayToday} onClick={startPaymentFlow} className="right"/></Col>
												</Form.Row>
												}
												</>
											}
											
										</Card.Body>
									</FormCard>
									</Col>
									} 
									
									{ showPaymentPanel && 
								<Col lg={(!showPaymentPlanCreator)?6:5}>
									<FormCard id="" className="green">
										<Card.Header as="h5">Make a Payment</Card.Header>
										<Card.Body>
											<Elements stripe={stripePromise}>
												<CreditCardForm amount={amountToPayToday} onSuccess={finishPayment} />
											</Elements>
										</Card.Body>
									</FormCard>
									</Col>
									} 
									
									
							</Row>
							}
						 </Container>		
					</>
	);
}
 
export default PaymentsSetup;