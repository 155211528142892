import React, { useState,useEffect,useRef }  from "react";

import { useParams } from "react-router-dom";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import { useMyRegistration } from "../../contexts/myregistration.js";

import { Typeahead, Token, Highlighter } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';


import { TextLink } from "../../styled-components/SmallElements.js";

import { Editor } from '@tinymce/tinymce-react';
import {
  useRouteMatch,
	useHistory
} from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faStar as fasStar } from '@fortawesome/free-solid-svg-icons'; 
import { faStar as falStar } from '@fortawesome/pro-light-svg-icons'; 
import { faExclamationCircle as farExclamationCircle } from '@fortawesome/pro-regular-svg-icons'; 
import { faExclamationCircle as fasExclamationCircle } from '@fortawesome/free-solid-svg-icons'; 
import { FormCard, FormLabel, RegularButton, SaveSpinnerContainer } from "../../styled-components/Forms.js";


import ThreadReply from './ThreadReply.js'; 
//   // TinyMCE so the global var exists
//   // eslint-disable-next-line no-unused-vars
//   import tinymce from 'tinymce/tinymce';

import Tabs from 'react-bootstrap/Tabs';
import Container from 'react-bootstrap/Container'
import Tab from 'react-bootstrap/Tab'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

import logo from "../../assets/PITMMiniLogo.png"; 

import './Messages.css'; 

library.add(
	fasStar,
	falStar,
	farExclamationCircle,
	fasExclamationCircle
);

function Messages(props){
	const regContext = useMyRegistration(); 
	const myRegistration = regContext.myRegistration; 
// 	const { myRegistration, setMyRegistration } = useMyRegistration(); 
// 	const myRegistration = useMyRegistration()[0]; 
  const { authToken, setAuthToken } = useAuth();
  let history = useHistory();
  let match = useRouteMatch();
	let { messageId } = useParams();
	
	const [tabKey, setTabKey] = useState((match.path==='/messages/new')?'new':'messages');
	
	let defaultProfilePics = {
			pup:"/profile_generic_pup.png",
			dog:"/profile_generic_pup.png",
			cat:"/profile_generic_cat.png",
			pony:"/profile_generic_pony.png",
			'other-critter':"/profile_generic_other.png",
			handler:"/profile_generic_human.png",
			parent:"/profile_generic_human.png",
			trainer:"/profile_generic_human.png",
			friend:"/profile_generic_human.png",
			other:"/profile_generic_human.png"
}

	const [messageThreads,setMessageThreads] = useState([]); 
	
	const editPuppyPileAddMemberRef = useRef();
	const [autoCompleteList,setAutoCompleteList] = useState([]); 
	const [newMessageSubject,setNewMessageSubject] = useState(''); 
	const [newMessageBody,setNewMessageBody] = useState(''); 
	const [newMessageTo,setNewMessageTo] = useState(''); 
	const [sendNewMsgToDirectors,setSendNewMsgToDirectors] = useState(false); 
	const [isSendingNewMessage,setIsSendingNewMessage] = useState(false); 
	
	function getMessages(callback=null){ 
		axios.get(process.env.REACT_APP_API_HOST+"/messages",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										setMessageThreads(result.data.messages); 
										if(callback!==null) callback(); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	useEffect(()=>{
		setMessageThreads([]); 
		getMessages(); 
		
		
		axios.get(process.env.REACT_APP_API_HOST+"/registrations/search?fields=guest.sceneName,guest.role,profile,profile.photos.primary",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										var res = []; 
										var thisobj; 
										for(var i in result.data.results){ 
											thisobj = {}; 
											thisobj.registrationId = result.data.results[i].id; 
											thisobj.name = result.data.results[i].guest.sceneName; 
											thisobj.role = result.data.results[i].guest.role;
											thisobj.profilePic = (result.data.results[i].profile.photos && result.data.results[i].profile.photos.length>0) ? 
																 result.data.results[i].profile.photos[0].uri//+'?size=small&bearer='+authToken.token
																 :
																 null; 
											res.push(thisobj); 
										} 
										setAutoCompleteList(res); 
									} else {
									}
								}).catch(e => {
								});	
		
	// eslint-disable-next-line
	},[]); 
	
	useEffect(()=>{     
		const timer = setTimeout(getMessages, 60000);
    return () => { clearTimeout(timer);}
	}); 
	
	
	
	function markAsRead(e){ 
		var thisThread; 
		for(var i=0;i<messageThreads.length;i++){ 
			if(messageThreads[i].id===e){
				thisThread = messageThreads[i]; 
				break; 
			}
		} 
		
		if(thisThread === undefined){ 
			return 0; 
		} 
		var messageIdsToRead = []; 
		
		if(thisThread.to && thisThread.to.registrationId===myRegistration.id && !thisThread.isRead) messageIdsToRead.push(thisThread.id); 
		
		for(var a=0;a<thisThread.replies.length;a++){ 
			if(thisThread.replies[a].to && thisThread.replies[a].to.registrationId===myRegistration.id){ 
				if(!thisThread.replies[a].isRead){ 
					messageIdsToRead.push(thisThread.replies[a].id); 
				} 
			} 
		} 
		
		if(messageIdsToRead.length>0){ 
			
		axios.put(process.env.REACT_APP_API_HOST+"/messages/"+messageIdsToRead.join(',')+"/status",{"status":"read"},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										getMessages();										
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
		} 
	} 
	function sendReply(threadid,reply){ 
		getMessages(); 
		
		var thisthread; 
		
		for(var i in messageThreads){ 
			if(messageThreads[i].id === threadid){
				thisthread = messageThreads[i]; 
				break; 
			}
		} 
		
		var messagePayload = {"body":reply,
													"subject":"Re: "+thisthread.subject,
													"from":myRegistration.id,
													"to":null}
		
		if(thisthread.toDirectors || thisthread.fromDirectors){ 
			messagePayload.to="directors"; 
		} else { 
			if(thisthread.from.registrationId===myRegistration.id){ 
				messagePayload.to = thisthread.to.registrationId; 
			} else if (thisthread.to.registrationId===myRegistration.id){ 
				messagePayload.to = thisthread.from.registrationId; 
			} 
		} 
		
		axios.post(process.env.REACT_APP_API_HOST+"/messages/"+threadid,messagePayload,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										getMessages(); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
		
	} 
	function formatTimeStamp(strDate){ 
		var date = new Date(strDate); 
		var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
		var suffix = 'th'; 
		var day = date.getDate(); 
		if(day===1 || day === 21 || day === 31) suffix = 'st'; 
		if(day===2 || day === 22) suffix = 'nd'; 
		if(day===3 || day === 23) suffix = 'rd'; 
		var string = months[date.getMonth()]+' '+date.getDate()+suffix+' @ '+date.toLocaleTimeString('en-US', {hour: '2-digit', minute: '2-digit'}); 
		return string; 
	} 
	
	function onSelectNewMessageTo(member){ 
		if(member.length>0)		setNewMessageTo(member[0].registrationId); 
	} 

	function handleEditorChange(content, editor){ 
		setNewMessageBody(content); 
	} 
	
	function sendNewMessage(){ 
		setIsSendingNewMessage(true); 
		
		var messagePayload = {"body":newMessageBody,
													"subject":newMessageSubject,
													"from":myRegistration.id,
													"to":null}
		if(sendNewMsgToDirectors===true){ 
			messagePayload.to="directors"; 
		} else { 
			messagePayload.to = newMessageTo;
		} 
		
		axios.post(process.env.REACT_APP_API_HOST+"/messages",messagePayload,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										getMessages(); 
										setTabKey('messages'); 
										setNewMessageBody(''); 
										setNewMessageSubject(''); 
										setNewMessageTo(''); 
										setSendNewMsgToDirectors(false); 
										editPuppyPileAddMemberRef.current.clear(); 
										history.push('/messages');
										setIsSendingNewMessage(false); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
		
		
		
	} 
	
	
	return (<>
						<Container>
							<h1>Messages</h1>
							<Tabs id="messages-tab-interface"
										activeKey={tabKey}
										onSelect={(k) => { if(k==='new') history.push('/messages/new'); 
																			 if(k==='messages') history.push('/messages'); 
																			 setTabKey(k); }}>
								<Tab eventKey="messages" title="My Messages" >
									
									<FormCard id="" className="orange no-bottom-margin">
										<Card.Header as="h5">Messages &amp; Conversations</Card.Header>
										<Card.Body class="messagesAccordion">
											<Accordion defaultActiveKey={messageId}>
	{messageThreads.map((thread)=>{ 
		var tofrom; 
		var profilePicURL; 
		var readStatus = 'read'; 
		var hasNewReply = true; 
		if(thread.from && thread.to){ 
			if(thread.from.registrationId===myRegistration.id && thread.replies.length===0){ 
				tofrom = <><small>To:</small> {thread.to.name}</>
				profilePicURL = (thread.to.profilePic)?process.env.REACT_APP_API_HOST+thread.to.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[thread.to.role]
				
			} else if(thread.from.registrationId===myRegistration.id && thread.replies.length>0) { 
				tofrom = <>{thread.to.name}</>; 
				profilePicURL = (thread.to.profilePic)?process.env.REACT_APP_API_HOST+thread.to.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[thread.to.role]
			} else { 
				tofrom = <>{thread.from.name}</>; 
				profilePicURL = (thread.from.profilePic)?process.env.REACT_APP_API_HOST+thread.from.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[thread.from.role]
			}
		} else if(thread.fromDirectors || (thread.toDirectors && thread.replies.length>0)){ 
			tofrom = <>PITM Directors</>
		} else if(thread.toDirectors && thread.replies.length===0){ 
			tofrom = <><small>To:</small> PITM Directors</>
		} 

		if(thread.to && thread.to.registrationId===myRegistration.id && !thread.isRead){ 
			readStatus = 'unread'; 
		} else if(thread.replies){ 
			for(var i in thread.replies){ 
				if(thread.replies[i].to && thread.replies[i].to.registrationId===myRegistration.id && !thread.replies[i].isRead){ 
					readStatus = 'unread'; 
					hasNewReply = true; 
				} 
			} 
		} 

		var latestDate = (thread.replies.length>0) ? 
				formatTimeStamp(thread.replies[thread.replies.length-1].timestamp) 
				: 
				formatTimeStamp(thread.timestamp); 
		
		var cardClass = ''; 
		if(thread.isAnnouncement){ cardClass+='announcement '; } else { cardClass+='message '; }
		if(thread.priority===2){ cardClass+=' priority-2 '; } else if(thread.priority===1){ cardClass+=' priority-1 '; }

		
		return (
  <Card key={thread.id} className={cardClass}>
		<Accordion.Toggle as={Card.Header} eventKey={thread.id} className={readStatus} onClick={(e)=>markAsRead(thread.id)}>
			<Row>
				<Col xs={12} sm={{span:6,order:1}} md={{span:6,order:1}} lg={{span:2,order:1}} className="tofrom">{(thread.fromDirectors || thread.toDirectors) && <img src={logo} alt="directors" width="30" height="30" className="directors" />}{ profilePicURL && <img src={profilePicURL} alt={tofrom} width="30" height="30" className="guest" />}{tofrom}</Col>
				<Col xs={12} sm={{span:12,order:4}} md={{span:12,order:4}} lg={{span:6,order:2}} className="subject">
					{thread.priority===1 && <FontAwesomeIcon size="lg" icon={['far', 'exclamation-circle']} />} 
					{thread.priority===2 && <FontAwesomeIcon size="lg" icon={['fas', 'exclamation-circle']} />} 
					{thread.isAnnouncement && <small>{(thread.priority===2)?'Urgent':((thread.priority===1)?'Important':'Announcement')}</small>} {thread.subject}</Col>
				<Col xs={10} sm={{span:4,order:2}} md={{span:3,order:2}} lg={{span:2,order:3}} className="date">{latestDate}</Col>
				<Col xs={1} sm={{span:1,order:3}} md={{span:2,order:3}} lg={{span:2,order:4}} className="star">{readStatus==='unread' && <><FontAwesomeIcon size="lg" icon={['fas', 'star']} /><small>New{hasNewReply && <> Reply</>}!</small></>}</Col>
			</Row>
    </Accordion.Toggle>
    <Accordion.Collapse eventKey={thread.id}>
      <Card.Body className="messagethread">
				<h6>{thread.subject}</h6>
				<div className="message">
					<Row className="envelope">
						<Col xs={1} sm={2} lg={1} className="icon">
								{ thread.fromDirectors && <img src={logo} alt="directors" width="50" height="50" className="directors" /> }
								{ (!thread.fromDirectors && thread.from.profilePic) && <img src={process.env.REACT_APP_API_HOST+thread.from.profilePic+'?size=small&bearer='+authToken.token} alt="guest" width="50" height="50" className="guest" /> }
								{ (!thread.fromDirectors && !thread.from.profilePic) && <img src={process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[thread.from.role]} alt="guest" width="50" height="50" className="guest" /> }
						</Col>
						<Col xs={12} sm={10} lg={11} className="detail">
							<div><b>From:</b> { thread.fromDirectors ? "PITM Directors":((thread.from.registrationId===myRegistration.id)?'Me':thread.from.name)}</div>
							<div><b>To:</b> { thread.toDirectors ? "PITM Directors":((thread.to.registrationId===myRegistration.id)?'Me':thread.to.name)}</div>
							<div><b>Sent:</b> { formatTimeStamp(thread.timestamp) }</div>
							{ thread.to && thread.to.registrationId===myRegistration.id && (thread.isRead || thread.isReadByEmail) && <div><i>{thread.isReadByEmail ? "You read this in email on "+formatTimeStamp(thread.firstReadTimestamp) : (thread.isRead ? "You read this on "+formatTimeStamp(thread.firstReadTimestamp):"")}</i></div>}
						</Col>
					</Row>
					<Row className="body">
						<Col dangerouslySetInnerHTML={{
    __html: thread.body
  }}></Col>
					</Row>
				</div>
				{ thread.replies.map((reply)=>
					<div className="message" key={reply.id}>
					<Row className="envelope">
						<Col xs={1} sm={2} lg={1} className="icon">
								{ reply.fromDirectors && <img src={logo} alt="directors" width="50" height="50" className="directors" /> }
								{ (!reply.fromDirectors && reply.from.profilePic) && <img src={process.env.REACT_APP_API_HOST+reply.from.profilePic+'?size=small&bearer='+authToken.token} alt="guest" width="50" height="50" className="guest" /> }
								{ (!reply.fromDirectors && !reply.from.profilePic) && <img src={process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[reply.from.role]} alt="guest" width="50" height="50" className="guest" /> }
						</Col>
						<Col xs={12} sm={10} lg={11} className="detail">
							<div><b>From:</b> { reply.fromDirectors ? "PITM Directors":((reply.from.registrationId===myRegistration.id)?'Me':reply.from.name)}</div>
							<div><b>To:</b> { reply.toDirectors ? "PITM Directors":((reply.to.registrationId===myRegistration.id)?'Me':reply.to.name)}</div>
							<div><b>Sent:</b> { formatTimeStamp(reply.timestamp) }</div>
							{ reply.to !== undefined && reply.to.registrationId===myRegistration.id && (reply.isRead || reply.isReadByEmail) && <div><i>{reply.isReadByEmail ? "You read this in email on "+formatTimeStamp(thread.firstReadTimestamp) : (reply.isRead ? "You read this on "+formatTimeStamp(reply.firstReadTimestamp):"")}</i></div>}
						</Col>
					</Row>
					<Row className="body">
						<Col dangerouslySetInnerHTML={{
    __html: reply.body
  }}></Col>
					</Row>
				</div>
				)}
				<ThreadReply onSend={sendReply} threadId={thread.id} />
			</Card.Body>
    </Accordion.Collapse>
  </Card> ) })}
											</Accordion>
										</Card.Body> 
									</FormCard>
								</Tab>
								<Tab eventKey="new" title="Send a Message"  >

									<FormCard id="" className="green no-bottom-margin">
										<Card.Header as="h5">New Message</Card.Header>
										<Card.Body>				
											<Form.Group as={Row}>
												<Col sm={1} md={2} lg={1}>
													<FormLabel.Primary>To</FormLabel.Primary>
												</Col>
												<Col sm={11} md={10} lg={11}>
													{ !sendNewMsgToDirectors && <>
																	  <Typeahead
																			clearButton
																			id="sendNewMessageTo"
																			labelKey="name"
																			flip
																			highlightOnlyResult
																			minLength={2}
																			ref={editPuppyPileAddMemberRef}   
																			options={autoCompleteList}
																			placeholder="Start typing the name of a guest"
																			onChange={onSelectNewMessageTo}
																			renderMenuItemChildren = {(option, { text }, index) => (
																					<div>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="45"/>
																						<Highlighter search={text}>
																						{option.name}
																						</Highlighter>
																					</div>
																				)}
																			renderToken = {(option, { onRemove }, index) => (
																					<Token
																						key={index}
																						onRemove={onRemove}
																						option={option}>
																						<img src={(option.profilePic)?process.env.REACT_APP_API_HOST+option.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[option.role]} alt={option.name} width="30" height="30" />
																						{option.name}
																					</Token>
																				)}
																		/>

													<TextLink className="sendToDirectorsLink" onClick={(e)=>{ setSendNewMsgToDirectors(true); }}>or, Send a Message to the Event Directors</TextLink>
													
													</> }
													{sendNewMsgToDirectors && <>Event Directors (<TextLink onClick={(e)=>{ setSendNewMsgToDirectors(false); }}>Send to Another Guest</TextLink>)</> }
			
    										</Col>
											</Form.Group>
											<Form.Group as={Row}>
												<Col sm={1} md={2} lg={1}>
													<FormLabel.Primary>Subject</FormLabel.Primary>
												</Col>
												<Col sm={11} md={10} lg={11}>
												<Form.Control id="newmessage_subject" value={newMessageSubject} onChange={e => { setNewMessageSubject(e.target.value); }}/>
												</Col>
												</Form.Group>
											<Form.Group as={Row}>
												<Col className="newMessage">
													<FormLabel.Primary>Message</FormLabel.Primary>
													
							<Editor
								apiKey='fn70u8o0lfx4eb27syw6okc0qo0zlg2sfmze2m0b99x16kvs'
								value={newMessageBody}
								init={{
									 height: 300,
									 menubar: false,
									plugins: 'emoticons',
									 toolbar:
										 'bold italic strikethrough | forecolor backcolor emoticons | bullist numlist | removeformat '
								 }}
								 onEditorChange={handleEditorChange}
							 />
												</Col>
												</Form.Group>
					<Row>
						<Col>
							
							
						{isSendingNewMessage ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
							<RegularButton onClick={(e)=>sendNewMessage()}>Send Message</RegularButton>
 						}
							
							
					</Col>
				</Row>
										</Card.Body>
									</FormCard>
				
								</Tab>
							</Tabs>
						</Container>
					</>
	);
}
 
export default Messages;