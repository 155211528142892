import React, { useState }  from "react";
import axios from 'axios';
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import { AuthContext } from "../contexts/auth";
import { EventSettingsContext } from "../contexts/eventsettings";
import { MyRegistrationContext } from "../contexts/myregistration.js";

import PrivateRoute from './PrivateRoute';

import AppFrame from './AppFrame'; 
import Login from './Login'; 

import ResetPassword from '../main-interfaces/ResetPassword'; 
import Dashboard from '../main-interfaces/Dashboard'; 
import Pack from '../main-interfaces/Pack/Pack'; 
import Meet from '../main-interfaces/Pack/Meet'; 
import Profile from '../main-interfaces/Profile/Profile.js'; 
import Housing from '../main-interfaces/PuppyPile/Housing'; 
import Payments from '../main-interfaces/Payments/Payments.js'; 
import PaymentsSetup from '../main-interfaces/Payments/PaymentsSetup'; 
import Messages from '../main-interfaces/Messages/Messages'; 
import DataUsage from '../main-interfaces/DataUsage'; 


const AppFooter = () => (
  <footer className="footer">
    <span>&copy;2024 Puppies In The Mountains, Inc. • <Link to="/data-terms">Data Usage, Privacy, & Security Policies</Link></span>
  </footer>
);

function App(props){ 
	const existingToken = JSON.parse(localStorage.getItem("authToken"));
	const [authToken, setAuthToken] = useState(existingToken);
	const [myRegistration, setMyRegistration] = useState({});
	// eslint-disable-next-line
	const [isLoadRegistrationError, setIsLoadRegistrationError] = useState(false);
//	#TODO: A single event is hardcoded here. Need some logic to support multiple + historical events the user is registered for. 
	const [eventSettings, setEventSettings] = useState({key:'pitm2024',startDate:new Date('2024-09-27'),name:'PITM 2024',paymentsDueDate:new Date('2024-08-31')});
	const setTokens = (data) => {
		localStorage.setItem("authToken", JSON.stringify(data));
		setAuthToken(data);
	}
									 
	function getMyReg(){
		axios.get(process.env.REACT_APP_API_HOST+"/registrations/mine",{headers: {'Bearer': authToken.token}})
      		.then(result => {
									if (result.status === 200) {
										setMyRegistration(result.data); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
										setIsLoadRegistrationError(true); 
									}
								}).catch(e => {
									setIsLoadRegistrationError(true); 
								});
  }

	if(!myRegistration.status && authToken) getMyReg(); 
	
	function saveRegistration(reg){ 
		reg.status = 'saving'; 
		setMyRegistration(reg); 
		axios.put(process.env.REACT_APP_API_HOST+"/registrations/"+myRegistration.id,myRegistration,{headers: {'Bearer': authToken.token}})
      		.then(result => {
									if (result.status === 200) {
										setMyRegistration(result.data); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
										setIsLoadRegistrationError(true); 
									}
								}).catch(e => {
									setIsLoadRegistrationError(true); 
								});
	}
	
	return (
		<AuthContext.Provider value={{ authToken, setAuthToken: setTokens }}>
		<EventSettingsContext.Provider value={{ eventSettings, setEventSettings }}>
		<MyRegistrationContext.Provider value={{ myRegistration, setMyRegistration:saveRegistration, refreshRegistration:getMyReg }}>
			<BrowserRouter>
				<div className="content">
				<AppFrame />
				<Switch>
					<Route path="/login" component={Login} />
					<Route path="/payments/finish-registration" component={PaymentsSetup} />
					<Route path="/reset-password" component={ResetPassword} />
					<PrivateRoute path="/" exact component={Dashboard} />
					<PrivateRoute path="/pack/meet/:guestId" component={Meet} />
					<PrivateRoute path="/pack" component={Pack} />
					<PrivateRoute path="/profile/travel" component={Profile} />
					<PrivateRoute path="/profile/private" component={Profile} />
					<PrivateRoute path="/profile" component={Profile} />
					<PrivateRoute path="/puppypile/housingtypes" component={Housing} />
					<PrivateRoute path="/puppypile" component={Housing} />
					<PrivateRoute path="/payments" component={Payments} refreshRegistration={getMyReg} />
					<PrivateRoute path="/messages/new" component={Messages} />
					<PrivateRoute path="/messages/:messageId" component={Messages} />
					<PrivateRoute path="/messages" component={Messages} />
					<PrivateRoute path="/data-terms" component={DataUsage} />
				</Switch>
				</div>
				<AppFooter />
			</BrowserRouter>
		</MyRegistrationContext.Provider>
		</EventSettingsContext.Provider>
		</AuthContext.Provider>
    );
}
 
export default App;