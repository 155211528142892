import React, {useState,useEffect} from "react";
import axios from 'axios';
import { useAuth } from "../../contexts/auth";
import styled from 'styled-components';
// import ScrollToBottom from 'react-scroll-to-bottom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import { faCommentLines } from '@fortawesome/pro-solid-svg-icons'; 


import { RegularButton } from "../../styled-components/Forms.js";

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
// import { Editor } from '@tinymce/tinymce-react';

const SendButton = styled(RegularButton)`
	padding: 5px 10px;
	background-color:#0d9348; 
float:right; 
	margin:0px 3px 5px; 
	&:hover{ 
		background-color:#0d9348; 
	}
`; 



library.add(
	faCommentLines
);

var chatMessages = [];
var chatMessageIds = []; 


function PuppyPileChat(props) {
  const { authToken, setAuthToken } = useAuth();
	const myPuppyPile = props.puppyPile; 
	const [chatHistory,setChatHistory] = useState([]); 
	const [newMessage,setNewMessage] = useState(''); 
	const [isPosting,setIsPosting] = useState(false); 

	let defaultProfilePics = {
				pup:"/profile_generic_pup.png",
				dog:"/profile_generic_pup.png",
				cat:"/profile_generic_cat.png",
				pony:"/profile_generic_pony.png",
				'other-critter':"/profile_generic_other.png",
				handler:"/profile_generic_human.png",
				parent:"/profile_generic_human.png",
				trainer:"/profile_generic_human.png",
				friend:"/profile_generic_human.png",
				other:"/profile_generic_human.png"
	}
	
	
	function getLastChatTimestamp(){ 
		if(!chatMessages || chatMessages.length===0){ return null; }
		return chatMessages[chatMessages.length-1].timestamp; 
	} 
	
	function addMessagesToChatHistory(messages){ 
		var cloneHistory = (!chatMessages || chatMessages.length===0) ? [] : [...chatMessages]
		var cloneIds = (!chatMessageIds || chatMessageIds.length===0) ? [] : [...chatMessageIds]

		for(var i in messages){ 
			if(!cloneIds.includes(messages[i].id)){ 
				cloneHistory.push(messages[i]); 
				cloneIds.push(messages[i].id); 
			}
		} 
		cloneHistory.sort((a, b) => b.timestamp - a.timestamp); 
		chatMessages = cloneHistory; 
		chatMessageIds = cloneIds; 
		setChatHistory(cloneHistory); 
		localStorage.setItem("chat_"+myPuppyPile.id,JSON.stringify(cloneHistory));
	} 
	
	function formatTimeStamp(strDate){ 
		var date = new Date(strDate); 
		var months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]; 
		var suffix = 'th'; 
		var day = date.getDate(); 
		if(day===1 || day === 21 || day === 31) suffix = 'st'; 
		if(day===2 || day === 22) suffix = 'nd'; 
		if(day===3 || day === 23) suffix = 'rd'; 
		var string = months[date.getMonth()]+' '+date.getDate()+suffix+' @ '+date.toLocaleTimeString('en-US'); 
		return string; 
	} 
	
	function getLatestChats(callback=null){ 
		var lastChatTimestamp = getLastChatTimestamp(); 
		var startTime = (lastChatTimestamp===null)? '':'?startTime='+lastChatTimestamp.replace('+','%2B'); 

		axios.get(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id+"/messages"+startTime,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										addMessagesToChatHistory(result.data.messages); 
										if(callback!==null) callback(); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
	useEffect(()=>{
		chatMessages = [];
		chatMessageIds = []; 
		setChatHistory([]); 
		var existingStoredChat = localStorage.getItem("chat_"+myPuppyPile.id);
		if(existingStoredChat){
			addMessagesToChatHistory(JSON.parse(existingStoredChat)); 
		} 
		getLatestChats(); 
	// eslint-disable-next-line
	},[]); 
	
	useEffect(()=>{     
		const timer = setTimeout(getLatestChats, 10000);
    return () => { clearTimeout(timer);}
	}); 
	
	function postMessage(){ 
		
		setIsPosting(true); 

		axios.post(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPuppyPile.id+"/messages",{message:newMessage},{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										setNewMessage(''); 
										setIsPosting(false); 
										addMessagesToChatHistory([result.data]); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
	} 
	
  return ( <div className="chat_interface">
			<Container className="chat_log">
				<Container className="scroller">
					{ chatHistory && chatHistory.map((message)=>{
						if(message.isAction){ 
							return (<Row key={message.id} className="chat_action">
									<Col><small className="timestamp">{formatTimeStamp(message.timestamp)}</small><p>{message.message}</p></Col>
									</Row>); 
						} else { 
							return (<Row key={message.id} className="chat_message">
									<Col xs={1} className="thumbnail">
										<img src={message.user.profilePic?process.env.REACT_APP_API_HOST+message.user.profilePic+'?size=small&bearer='+authToken.token:process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[message.user.role]} alt={message.user.name} width="50" height="50" />
									</Col>
									<Col xs={11}><span className="name">{message.user.name} <small className="timestamp">{formatTimeStamp(message.timestamp)}</small></span>
										<p>{message.message}</p></Col>
									</Row>); 
						} 
					}) }
				</Container>
			</Container>
			<Container className="chat_entry">
				<Row>
					<Col xs={12} sm={12} md={9} lg={10}>
						<Form.Control id="newMessageEditor" value={newMessage} onChange={(e)=>{setNewMessage(e.target.value)}} />

					</Col>
					<Col xs={12} sm={12} md={3} lg={2}  className="submit">
						{isPosting?<Spinner animation="grow" variant="success" />:<SendButton onClick={postMessage}><FontAwesomeIcon icon={['fas', 'comment-lines']}  /> Post</SendButton>}
					</Col>
				</Row>
			</Container>
		</div>
					);
}
 
export default PuppyPileChat;