import React, { useState,useEffect }  from "react";
import axios from 'axios';
import { useParams } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useEventSettings } from "../../contexts/eventsettings";

import { FormCard, FormLabel } from "../../styled-components/Forms.js";

import { LoadingSpinner } from "../../styled-components/AuthForms.js";
import { RegularButton } from "../../styled-components/Forms.js";
import { TextLink } from "../../styled-components/SmallElements.js";


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Spinner from 'react-bootstrap/Spinner'
import Carousel from 'react-bootstrap/Carousel'
import Form from 'react-bootstrap/Form'

import { Editor } from '@tinymce/tinymce-react';

import './Meet.css'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost
} from '@fortawesome/free-brands-svg-icons';
import {
  faArrowCircleRight,
  faArrowCircleLeft,
	faDog,
	faCat,
	faHorse,
	faHeart,
	faSmile,
	faPaw
} from '@fortawesome/free-solid-svg-icons';
import {
  faHomeLg
} from '@fortawesome/pro-solid-svg-icons';
import {
  faCommentSmile,
	faBirthdayCake,
	faGlobe
} from '@fortawesome/pro-regular-svg-icons';
	
library.add(
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
	faArrowCircleRight,
	faArrowCircleLeft,
	faHomeLg,
	faCommentSmile,
	faBirthdayCake,
	faDog,
	faCat,
	faHorse,
	faHeart,
	faSmile,
	faPaw,
	faGlobe
);

function Pack(props) {
// 	const myRegistration = useMyRegistration()[0]; )
// 	const { myRegistration, setMyRegistration } = useMyRegistration(); 
	const regContext = useMyRegistration(); 
	const myRegistration = regContext.myRegistration; 
  const { eventSettings } = useEventSettings();
  const { authToken, setAuthToken } = useAuth();
	let { guestId } = useParams();

 	const [ guestProfile,setGuestProfile ] = useState(); 
 	const [ pileInfo,setPileInfo ] = useState(null); 
 	const [ pronounTranslations,setPronounTranslations ] = useState({
		"he-him-his":"He / Him",
		"she-her-hers":"She / Her",
		"they-them-theirs":"They / Them",
		"xe-xer-xyr":"Xe / Xyr",
		"other":"Other"

}); 
 	const [ roleTranslations,setRoleTranslations ] = useState({
  "pup": "Pup / Puppy",
  "dog": "Dog",
  "cat": "Cat / Kitty",
  "pony": "Pony",
  "other-critter": "Critter",
  "handler": "Handler",
  "parent": "Daddy / Mommy",
  "trainer": "Trainer",
  "friend": "Friend",
  "other": "Friend"
}); 
 	const [ isSendingMessage,setIsSendingMessage ] = useState(false); 
 	const [ isSendingNewMessage,setIsSendingNewMessage ] = useState(false); 
 	const [ isInvitingToPile,setIsInvitingToPile ] = useState(false); 
	const [newMessageSubject,setNewMessageSubject] = useState(''); 
	const [newMessageBody,setNewMessageBody] = useState(''); 
	
	let defaultProfilePics = {
			pup:"/profile_generic_pup.png",
			dog:"/profile_generic_pup.png",
			cat:"/profile_generic_cat.png",
			pony:"/profile_generic_pony.png",
			'other-critter':"/profile_generic_other.png",
			handler:"/profile_generic_human.png",
			parent:"/profile_generic_human.png",
			trainer:"/profile_generic_human.png",
			friend:"/profile_generic_human.png",
			other:"/profile_generic_human.png"
	}

	function getPileInfo(){ 
		axios.get(process.env.REACT_APP_API_HOST+"/housing/"+guestId,{headers: {'Bearer': authToken.token}})
				.then(result => {
								if (result.status === 200) {

									setPileInfo(result.data); 

									if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 

								} else {
								}
							}).catch(e => {
							});	
	} 
	
	
	useEffect(()=>{
		
		
		axios.get(process.env.REACT_APP_API_HOST+"/registrations/"+guestId,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {
										
										setGuestProfile(result.data); 
										 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
										
									} else {
									}
								}).catch(e => {
								});	
		
		if(myRegistration.id !== guestId){ 
			getPileInfo(); 
		}
	// eslint-disable-next-line
	},[]); 
	
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									
									result.data["guest.role"].other="Friend"; 
									result.data["guest.role"].friend="Friend"; 
									result.data["guest.role"]["other-critter"]="Critter"; 
									
									setRoleTranslations(result.data["guest.role"]); 
									
									setPronounTranslations(result.data["guest.pronouns"]); 
								} else {
								}
							}).catch(e => {
							});
	// eslint-disable-next-line
	},[]); 
	
	
	function inviteToPile(){ 
		setIsInvitingToPile(true); 
		axios.get(process.env.REACT_APP_API_HOST+"/housing/puppypiles/mine",{headers: {'Bearer': authToken.token}})
					.then(result => {
									if (result.status === 200) {

										var allPiles = result.data.puppyPiles
										var myPile; 
										for(var i in allPiles){ 
										for(var m in allPiles[i].members){ 
											if(allPiles[i].members[m].registrationId === myRegistration.id){ 
												if(allPiles[i].members[m].status==='accepted' || allPiles[i].members[m].status==='creator'){ 
													myPile = allPiles[i]; 
												}
											}
										}
										}
										
										var cloneMembers = [...myPile.members]; 
										cloneMembers.push({registrationId:guestProfile.id,status:'invited',may_edit_pile:false,may_add_users:false}); 

										axios.put(process.env.REACT_APP_API_HOST+"/housing/puppypiles/"+myPile.id+'/members',{members:cloneMembers},{headers: {'Bearer': authToken.token}})
													.then(result => {
																	if (result.status === 200) {
																		
																		getPileInfo(); 
																		
																		if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
																	} else {
																	}
																}).catch(e => {
																});	
										
										
										
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
				
	} 
	function startSendMessage(){ 
		setIsSendingMessage(true); 
	} 
	
	function handleEditorChange(content, editor){ 
		setNewMessageBody(content); 
	} 
	function sendMessage(){ 
		setIsSendingNewMessage(true); 
		
		var messagePayload = {"body":newMessageBody,
													"subject":newMessageSubject,
													"from":myRegistration.id,
													"to":guestProfile.id}
		
		axios.post(process.env.REACT_APP_API_HOST+"/messages",messagePayload,{headers: {'Bearer': authToken.token}})
					.then(result => {
									if(result.status === 200) {
										setIsSendingNewMessage(false); 
										setIsSendingMessage(false); 
										setNewMessageBody(''); 
										setNewMessageSubject(''); 
										if(result.headers['x-authtoken']) setAuthToken(JSON.parse(result.headers['x-authtoken'])); 
									} else {
									}
								}).catch(e => {
								});	
		
		
		
	} 
	
    return (<>
						<Container>
				{!guestProfile && 
							<LoadingSpinner>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />
						</LoadingSpinner>}
				{guestProfile && <>
			<Row className="profileTop">
				<Col>
					<h1>{guestProfile.guest.sceneName}</h1>
					{guestProfile.guest.guestType==='director' && <div className="typebadge director">Director</div>}
					{guestProfile.guest.guestType==='volunteer' && <div className="typebadge volunteer">Volunteer</div>}
					{guestProfile.guest.eventJob!==null && <div className="typebadge job">{guestProfile.guest.eventJob}</div>}
				</Col>
			</Row>
			
						<Row>
							<Col xs={{span:12, order: 2}} lg={{span:6,order:1}}>
								<FormCard id="" className="green">
									<Card.Header as="h5">All About Me</Card.Header>
									<Card.Body>
										<Row className="personal-details">
											<Col sm={6} className="profile-bit">
													{ (guestProfile.guest.role==='pup' || guestProfile.guest.role==='dog') && <FontAwesomeIcon icon="dog" size="lg" />}
													{ (guestProfile.guest.role==='cat') && <FontAwesomeIcon icon="cat" size="lg" />}
													{ (guestProfile.guest.role==='pony') && <FontAwesomeIcon icon="horse" size="lg" />}
													{ (guestProfile.guest.role==='other-critter') && <FontAwesomeIcon icon="paw" size="lg" />}
													{ (guestProfile.guest.role==='handler' || guestProfile.guest.role==='trainer' || guestProfile.guest.role==='parent') && <FontAwesomeIcon icon="heart" size="lg" />}
													{ (guestProfile.guest.role==='friend' || guestProfile.guest.role==='other') && <FontAwesomeIcon icon="smile" size="lg" />}
												{roleTranslations[guestProfile.guest.role]}
											</Col>
											<Col sm={6} className="profile-bit">
												<FontAwesomeIcon icon={['far', 'comment-smile']} size="lg" />
												{pronounTranslations[guestProfile.guest.pronouns]}
											</Col>
										</Row>
										{ (guestProfile.profile.preferences.showHometown || guestProfile.profile.preferences.showAge) &&
										<Row className="personal-details">
											{guestProfile.profile.preferences.showHometown && 
											<Col sm={6} className="profile-bit">
												<FontAwesomeIcon icon="home-lg" size="lg" />
												{guestProfile.profile.hometown.city}, {guestProfile.profile.hometown.state}
											</Col>
												}
											{guestProfile.profile.preferences.showAge && 
											<Col sm={6} className="profile-bit">
												<FontAwesomeIcon icon={['far', 'birthday-cake']} size="lg" />
												{guestProfile.guest.age}
											</Col>
												}
										</Row>
											}
										<Row className="guestBio">
											<Col dangerouslySetInnerHTML={{
    __html: guestProfile.profile.bio
  }}>
										</Col>
												</Row>
									</Card.Body>
								</FormCard>
								
								
								
								
								<FormCard id="" className="blue">
									<Card.Header as="h5">Connect with Me</Card.Header>
									<Card.Body className="guestProfileConnect">
										{ isSendingMessage ? <> 
										
										
											<Form.Group as={Row}>
												<Col sm={2}>
													<FormLabel.Primary>Subject</FormLabel.Primary>
												</Col>
												<Col sm={10}>
												<Form.Control id="newmessage_subject" value={newMessageSubject} onChange={e => { setNewMessageSubject(e.target.value); }}/>
												</Col>
												</Form.Group>
											<Form.Group as={Row}>
												<Col>
													<FormLabel.Primary>Message</FormLabel.Primary>
													
							<Editor
								apiKey='fn70u8o0lfx4eb27syw6okc0qo0zlg2sfmze2m0b99x16kvs'
								value={newMessageBody}
								init={{
									 height: 170,
									 menubar: false,
									plugins: 'emoticons',
									 toolbar:
										 'bold italic strikethrough | forecolor backcolor emoticons | bullist numlist | removeformat '
								 }}
								 onEditorChange={handleEditorChange}
							 />
												</Col>
												</Form.Group>
										<Row>
											<Col>
						{isSendingNewMessage ?  
						 <LoadingSpinner>
							<Spinner animation="grow" variant="primary" />
						 </LoadingSpinner>
							:<>
								<RegularButton onClick={(e)=>sendMessage()}>Send Message</RegularButton>
								<RegularButton className="low-key cancel" onClick={()=>{setIsSendingMessage(false);}}>Cancel</RegularButton>
												</>
 						}
											</Col>
											</Row>
										
										
										</> : <>
											{guestProfile.profile.website !==null && guestProfile.profile.website !=='' && 
												<Row className="social-links">
											<Col>
												<TextLink onClick={()=>{window.open(guestProfile.profile.website);}}><FontAwesomeIcon icon={['far', 'globe']} size="lg" />{guestProfile.profile.website}</TextLink>
											</Col>
											</Row>}
										<Row className="social-links">
											
											
											
				{guestProfile.profile.socialMedia.facebook!==null && guestProfile.profile.socialMedia.facebook!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://www.facebook.com/"+guestProfile.profile.socialMedia.facebook);}}><FontAwesomeIcon icon={['fab', 'facebook']} size="lg" />{guestProfile.profile.socialMedia.facebook}</TextLink></Col>}
				{guestProfile.profile.socialMedia.instagram!==null && guestProfile.profile.socialMedia.instagram!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://www.instagram.com/"+guestProfile.profile.socialMedia.instagram);}}><FontAwesomeIcon icon={['fab', 'instagram']} size="lg" />{guestProfile.profile.socialMedia.instagram}</TextLink></Col>}
				{guestProfile.profile.socialMedia.snapchat!==null && guestProfile.profile.socialMedia.snapchat!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://www.snapchat.com/add/"+guestProfile.profile.socialMedia.snapchat);}}><FontAwesomeIcon icon={['fab', 'snapchat-ghost']} size="lg" />{guestProfile.profile.socialMedia.snapchat}</TextLink></Col>}
				{guestProfile.profile.socialMedia.telegram!==null && guestProfile.profile.socialMedia.telegram!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://t.me/"+guestProfile.profile.socialMedia.telegram);}}><FontAwesomeIcon icon={['fab', 'telegram-plane']} size="lg" />{guestProfile.profile.socialMedia.telegram}</TextLink></Col>}
				{guestProfile.profile.socialMedia.twitter!==null && guestProfile.profile.socialMedia.twitter!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://twitter.com/"+guestProfile.profile.socialMedia.twitter);}}><FontAwesomeIcon icon={['fab', 'twitter']} size="lg" />{guestProfile.profile.socialMedia.twitter}</TextLink></Col>}
				{guestProfile.profile.socialMedia.tiktok!==null && guestProfile.profile.socialMedia.tiktok!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://tiktok.com/@"+guestProfile.profile.socialMedia.tiktok.replace('@',''));}}><FontAwesomeIcon icon={['fab', 'tiktok']} size="lg" />{guestProfile.profile.socialMedia.tiktok}</TextLink></Col>}
				{guestProfile.profile.socialMedia.twitch!==null && guestProfile.profile.socialMedia.twitch!=='' && <Col sm={6}><TextLink onClick={()=>{window.open("https://twitch.com/"+guestProfile.profile.socialMedia.twitch);}}><FontAwesomeIcon icon={['fab', 'twitch']} size="lg" />{guestProfile.profile.socialMedia.twitch}</TextLink></Col>}
											
											
										</Row>

										{guestProfile.profile.preferences.allowEmail && guestProfile.id!==myRegistration.id &&
										<Row>
											<Col>
												<RegularButton className="blue full-width" onClick={startSendMessage}>Send a Message</RegularButton>
											</Col>
										</Row>
											}
										 </>}
									</Card.Body>
								</FormCard>
								
								
								{pileInfo!==null && pileInfo.iAmInPile && (pileInfo.inMyPile || pileInfo.invitedToMyPile || pileInfo.canBeInvitedToMyPile) &&
								<FormCard id="" className="purple">
									<Card.Header as="h5">Puppy Pile</Card.Header>
									<Card.Body className="">
										{pileInfo.inMyPile && <p>{guestProfile.guest.sceneName} is in your Puppy Pile!</p> }
										{pileInfo.invitedToMyPile && <p>{guestProfile.guest.sceneName} has been invited to your Puppy Pile.</p> }
										{pileInfo.canBeInvitedToMyPile && <>
											{isInvitingToPile ? 
																<LoadingSpinner>
																	<Spinner animation="grow" variant="primary" />
																</LoadingSpinner>:
																<>
																<RegularButton className="full-width" onClick={inviteToPile}>Invite to My Puppy Pile</RegularButton>
																{pileInfo.inAnyPile && <p><small>{guestProfile.guest.sceneName} is already in a puppy pile, but can choose to switch to yours</small></p>}
																</>}
										 </>
										}

									</Card.Body>
								</FormCard>
								}
								
								
								
								
							</Col>
							<Col xs={{span:12, order: 1}} lg={{span:6,order:2}}>
								<FormCard id="photoCarousel" className="gold">
									<Card.Header as="h5">Photos</Card.Header>
									<Card.Body>
										<Carousel className="guestPhotosScroll" interval={10000}
											nextIcon=<FontAwesomeIcon icon="arrow-circle-right" size="2x" />
											prevIcon=<FontAwesomeIcon icon="arrow-circle-left" size="2x" />>
										{guestProfile.profile.photos.length===0 && 
												
											<Carousel.Item>
												<img src={process.env.REACT_APP_GCS_IMAGE_HOST+defaultProfilePics[guestProfile.guest.role]} width="400" height="400" alt="Guest"/>
											<Carousel.Caption>
												<p><i>{guestProfile.guest.sceneName} hasn't uploaded any photos yet</i></p>
											</Carousel.Caption>
										</Carousel.Item>
											}
										
										{guestProfile.profile.photos.length>0 && guestProfile.profile.photos.map((photo)=>{ 
												return (
										<Carousel.Item key={photo.id}>
											<img
												src={process.env.REACT_APP_API_HOST+photo.uri+'?size=medium&bearer='+authToken.token} width="100%" alt="Guest"
											/>
														{photo.caption!=='' &&
											<Carousel.Caption>
												<p>{photo.caption}</p>
											</Carousel.Caption> }
										</Carousel.Item>); 
										})}
										</Carousel>
									</Card.Body>
								</FormCard>
							</Col>
						</Row>
			
			
			
					</>}

						</Container>
</>
    );
}
 
export default Pack;