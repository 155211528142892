import styled from 'styled-components';
import Button from 'react-bootstrap/Button'

const TextLink = styled(Button)`
	display:inline; 
	color:#28377d; 
	text-align:left; 
	padding:0px; 
	margin:0px; 
	border:none; 
	&:hover{ 
		text-decoration:underline;
		background-color:transparent; 
		color:#28377d; 
	} 
	background-color:transparent; 
`; 
const ButtonLink = styled(Button)`
display:inline-block;
background-color:#c9e3ff; 
color:#28377d;
text-decoration:none; 
padding:3px 6px; 
margin:3px 0px; 
border:none; 
&:hover{ 
} 
&.single-line{ 
	display:block;
	margin:6px 0px;
}
`; 
const WideButtonLink = styled(Button)`
display:block;
width:100%; 
background-color:#c9e3ff; 
color:#28377d;
text-decoration:none; 
padding:3px 4px; 
margin:3px 0px; 
text-align:center;
border:none; 
&:hover{ 
} 
`; 
const BreadcrumbLink = styled(Button)`
background-color:transparent; 
color:#28377d;
text-decoration:none; 
padding:4px 2px; 
margin-bottom:30px; 
margin-top:0px; 
border:none; 
&:hover{ 
	background-color:#b6af2b; 
	color:#FFFFFF; 
} 
`; 
export { BreadcrumbLink, ButtonLink,TextLink,WideButtonLink };