//https://medium.com/better-programming/building-basic-react-authentication-e20a574d5e71

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../contexts/auth";

function PrivateRoute({ component: Component, ...rest }) {
  const { authToken } = useAuth();
	
	let regStatus = localStorage.getItem("registrationStatus"); 
	
	if(authToken){ 
		var expiry = new Date(authToken.expiresAt); 
		var now = new Date(); 
	} 
	
	
  return (
    <Route
      {...rest}
      render={props =>
        authToken && (now<expiry) ? (
					<Component {...props} />
					// (regStatus==="ok") ? <Component {...props} /> : <Redirect to={{ pathname: "/payments/finish-registration" }} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { referer: (props.location)?props.location:'/' } }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
