import styled from 'styled-components';
import Button from 'react-bootstrap/Button'

const AddPhotoButton = styled(Button)`
background-color:#0d9348; 
border:0px; 
 #AddFirstProfilePhoto &{ 
	padding:8px 24px; 
	font-size:18px; 
	font-weight:bold; 
} 
&:active{ 
	background-color:#0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}  
&:hover{ 
	background-color:#0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 
&.inverse{ 
	background-color:#FFFFFF; 
	border:1px solid #0d9348; 
	color:#0d9348; 
	margin-left:10px; 
} 
#DisplayProfilePhotos &{ 
	width:125px; 
	height:125px;
	margin-right:20px; 
	margin-bottom:20px; 
	text-align:center; 
} 
#DisplayProfilePhotos & svg{ 
	display:block; 
	margin:5px auto; 
} 
`; 

const DeletePhotoButton = styled(Button)`
	background-color:#b82a34; 
	border:0px; 
	&:active{ 
		background-color:#b82a34; 
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	} 
	&:hover{ 
		background-color:#b82a34; 
		box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
	} 
`; 

const EditPhotoButton = styled(Button).attrs({ className:"edit-photo-button" })`
background-color:#0d9348; 
border:0px; 
position:absolute; 
z-index:12; 
right:5px; 
bottom:5px;
opacity:0; 

&:active{ 
	background-color:#0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 
&:hover{ 
	background-color:#0d9348; 
	box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
} 
`; 

const ProfilePhoto = styled.div.attrs({ 
  className:'profilephoto'
})`
	width:125px; 
	border:1px solid #DEDEDE; 
	height:125px; 
	float:left; 
	margin-right:20px; 
	margin-bottom:20px; 
	position:relative; 
	z-index:10;
  background: url(${props => props.image}) center center no-repeat !important; 
	background-size: cover !important;
	.editPhotoShowPhoto &{ 
		width:125px; 
		height:125px; 
	}
`; 



export { AddPhotoButton, EditPhotoButton, ProfilePhoto, DeletePhotoButton };