import React, { useState } from "react";
import axios from 'axios';
// import { useAuth } from "../contexts/auth";

import { LoginCard, LoginForm, LoginInput, LoadingSpinner, StartReset,FinishReset } from "../styled-components/AuthForms.js";

import { Link } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

function ResetPassword(props) {
	const [signInTryCounter, setSignInTryCounter] = useState(0);
// 	const [isEnteringData,setIsEnteringData] = useState(false); 
	const [isError, setIsError] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [checkingEmail, setCheckingEmail] = useState(false);
	const [resettingPass, setResettingPass] = useState(false);
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
// 	const { setAuthToken } = useAuth();
	const [privateKey,setPrivateKey] = useState(''); 
	const [publicKey,setPublicKey] = useState(''); 
	const [started,setStarted] = useState(false); 
	const [isComplete,setIsComplete] = useState(false); 
	
	function resetPass(e) {
		e.preventDefault();
		setCheckingEmail(true); 
		setIsError(false);
		setSignInTryCounter(signInTryCounter+1); 
		axios.get(process.env.REACT_APP_API_HOST+"/users/"+email+"/reset-password")
			.then(result => {
			if (result.status === 200) {
				setStarted(true);
				setCheckingEmail(false); 
				setPublicKey(result.data.resetKey); 
			} else {
				setIsError(true);
				setCheckingEmail(false); 
				if(result.data.error==='user-not-found'){ 
					setErrorMessage("That email address is not registered for Puppies In the Mountains 2021."); 
				} else { 
					setErrorMessage("Sorry, something went wrong. Please email webmaster@puppiesinthemountains.com."); 
				} 
			}
		}).catch(e => {
			setIsError(true);
				setCheckingEmail(false); 
				if(e.response.data.error==='user-not-found'){ 
					setErrorMessage("That email address is not registered for Puppies In the Mountains 2021. "); 
				} else { 
					setErrorMessage("Sorry, something went wrong. Please email webmaster@puppiesinthemountains.com."); 
				} 
		});
	}
	function verifyAndChange(e){ 
				setIsError(false);
		setResettingPass(true)
		axios.post(process.env.REACT_APP_API_HOST+"/users/"+email+"/reset-password", {"resetKey":publicKey,"privateKey":privateKey,"password":password})
			.then(result => {
			if (result.status === 200) {
				setIsComplete(true); 
				setResettingPass(false); 
			} else {
				setIsError(true);
				setResettingPass(false); 
				if(result.data.error==='user-not-found'){ 
					setErrorMessage("That email address is not registered for Puppies In the Mountains 2021."); 
				} else if(result.data.error==='no-pwd-reset'){ 
					setErrorMessage("The code you entered was invalid, or has expired. Please check the code we emailed you, or start the reset password process again."); 
				} else { 
					setErrorMessage("Sorry, something went wrong. Please email webmaster@puppiesinthemountains.com."); 
				} 
			}
		}).catch(e => {
			setIsError(true);
				setResettingPass(false); 
				if(e.response.data.error==='user-not-found'){ 
					setErrorMessage("That email address is not registered for Puppies In the Mountains 2021."); 
				} else if(e.response.data.error==='no-pwd-reset'){ 
					setErrorMessage("The code you entered was invalid, or has expired. Please check the code we emailed you, or start the reset password process again."); 
				} else { 
					setErrorMessage("Sorry, something went wrong. Please email webmaster@puppiesinthemountains.com."); 
				} 
		});
		
	} 
	
	
// 	if(hideForm){ 
// 		return <LoadingSpinner>
// 							<Spinner animation="grow" variant="primary" />&nbsp;
// 							<Spinner animation="grow" variant="secondary" />&nbsp;
// 							<Spinner animation="grow" variant="success" />&nbsp;
// 							<Spinner animation="grow" variant="danger" />&nbsp;
// 							<Spinner animation="grow" variant="warning" />
// 						</LoadingSpinner>; 
// 	} 
	return (
		<LoginCard>
  <Card.Header as="h5">Reset Password</Card.Header>
		{ isError && <Alert variant="danger">{errorMessage}</Alert> }
			{isComplete && 
  <Card.Body>
					<p>Your password has been reset! <Link to="/">Now you can sign in</Link> with your email address and new password.</p>
					</Card.Body> } 
			
			{ started && !isComplete && 
  <Card.Body>
		<p>We've sent you an email with a code. Copy and paste that code into this field:</p>
			<LoginInput
					type="email"
					value={privateKey}
					onChange={e => {
						setPrivateKey(e.target.value);
// 						setIsEnteringData(true); 
					}}
					onFocus={e => {
// 						setIsEnteringData(true); 
					}}
					placeholder="enter code"
				/>
					{privateKey!=='' && <>
		<p>And choose a new password:</p>
				<LoginInput
					type="password"
					value={password}
					onChange={e => {
						setPassword(e.target.value);
					}}
					onFocus={e => {
// 						setIsEnteringData(true); 
					}}
					placeholder="password"
				/>
				
				{!resettingPass &&
		<FinishReset onClick={verifyAndChange} />
					}
				{resettingPass &&
				<LoadingSpinner>
 					<Spinner animation="grow" variant="success" />
 				</LoadingSpinner>
					}
					 
		
				
				</> }
					
					</Card.Body>}
			{ !started && !isComplete && 
  <Card.Body>
		<p>Enter your email address and we'll email you a reset code.</p>
			<LoginForm onSubmit={resetPass}>
			<LoginInput
					type="email"
					value={email}
					onChange={e => {
						setEmail(e.target.value);
// 						setIsEnteringData(true); 
					}}
					onFocus={e => {
// 						setIsEnteringData(true); 
					}}
					placeholder="email"
				/>
				{!checkingEmail &&
		<StartReset />
					}
				{checkingEmail &&
				<LoadingSpinner>
 					<Spinner animation="grow" variant="success" />
 				</LoadingSpinner>
					}
			</LoginForm>
		<Link to="/">Nevermind, I remembered!</Link>
  </Card.Body>
				}
</LoginCard>
	);
}
export default ResetPassword;