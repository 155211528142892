import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useMyRegistration } from "../../contexts/myregistration.js";
import { useEventSettings } from "../../contexts/eventsettings";

import { FormCard, FormLabel, IconInputLabel, StyledInputGroup, SaveButton, SaveSpinnerContainer } from "../../styled-components/Forms.js";

import ProfilePhotosManager from './PhotosManager.js'; 

import Switch from '../../custom-widgets/Switch.js'; 

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
} from '@fortawesome/free-brands-svg-icons';
import { faCamera } from '@fortawesome/pro-regular-svg-icons'; 
import { faEdit } from '@fortawesome/pro-solid-svg-icons'; 

import InputGroup from 'react-bootstrap/InputGroup'
import Form from 'react-bootstrap/Form'
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Spinner from 'react-bootstrap/Spinner'
import { Editor } from '@tinymce/tinymce-react';

import '../../custom-widgets/Switch.css'; 

library.add(
  faTwitter,
  faInstagram,
	faFacebook,
	faTelegramPlane,
	faTwitch,
	faTiktok,
	faSnapchatGhost,
	faCamera,
	faEdit,
);

function PublicInfoEditor(props) {
	const { myRegistration, setMyRegistration } = useMyRegistration(); 
  const { eventSettings } = useEventSettings();
	const [isSavingToServer, setIsSavingToServer] = useState(false);
	
	const [isEdited, setIsEdited] = useState(false);
	const [website, setWebsite] = useState();
	const [instagram, setInstagram] = useState('Loading...');
	const [twitter, setTwitter] = useState('Loading...');
	const [facebook, setFacebook] = useState('Loading...');
	const [telegram, setTelegram] = useState('Loading...');
	const [twitch, setTwitch] = useState('Loading...');
	const [tiktok, setTiktok] = useState('Loading...');
	const [snapchat, setSnapchat] = useState('Loading...');
	const [sceneName, setSceneName] = useState('Loading...');
	const [hometownCity, setHometownCity] = useState('Loading...');
	const [hometownState, setHometownState] = useState('');
	const [role, setRole] = useState('');
	const [pronouns, setPronouns] = useState('');
	const [hometownZip, setHometownZip] = useState('...');
	const [hometownStateOptions, setHometownStateOptions] = useState({}); 
	const [pronounsOptions, setPronounsOptions] = useState({}); 
	const [roleOptions, setRoleOptions] = useState({}); 
	const [showHometown, setShowHometown] = useState(false); 
	const [showAge, setShowAge] = useState(false); 
	const [allowMessages, setAllowMessages] = useState(false); 
	const [bio, setBio] = useState('<i>Loading...</i>')
// 	const [bioCursorPos, setBioCursorPos] = useState(null);
	
	useEffect(() => {
	axios.get(process.env.REACT_APP_API_HOST+"/events/"+eventSettings.key+"/fieldoptions")
				.then(result => {
								if (result.status === 200) {
									setHometownStateOptions(result.data["guest.homestate"]); 
									setRoleOptions(result.data["guest.role"]); 
									setPronounsOptions(result.data["guest.pronouns"]); 
								} else {
								}
							}).catch(e => {
							});
	},[eventSettings.key]); 
	
	function handleFieldChange(id,val){ 
		setIsEdited(true); 
		if(id==='social_instagram') setInstagram(val); 
		if(id==='social_twitter') setTwitter(val); 
		if(id==='social_facebook') setFacebook(val); 
		if(id==='social_telegram') setTelegram(val); 
		if(id==='social_twitch') setTwitch(val); 
		if(id==='social_tiktok') setTiktok(val); 
		if(id==='social_snapchat') setSnapchat(val); 
		if(id==='profile_scenename') setSceneName(val); 
		if(id==='profile_website') setWebsite(val); 
		if(id==='profile_city') setHometownCity(val); 
		if(id==='profile_state') setHometownState(val); 
		if(id==='profile_zip') setHometownZip(val); 
		if(id==='profile_pronouns') setPronouns(val); 
		if(id==='profile_role') setRole(val); 
	} 
	function handleEditorChange(content, editor){ 
// 		setIsEdited((content !== bio)); 
		setIsEdited(true); 
// 		console.log(editor.getContent({ format: 'text' }).length); 
// 		setBioCursorPos(editor.selectionStart); 
		setBio(content); 
// 		editor.selectionStart = bioCursorPos;
	} 
	function savePublicProfile(){ 
		
		if(website!==null) myRegistration.profile.socialMedia.website = website; 
		if(instagram!==null) myRegistration.profile.socialMedia.instagram = instagram; 
		if(twitter!==null) myRegistration.profile.socialMedia.twitter = twitter; 
		if(facebook!==null) myRegistration.profile.socialMedia.facebook = facebook; 
		if(telegram!==null) myRegistration.profile.socialMedia.telegram = telegram; 
		if(twitch!==null) myRegistration.profile.socialMedia.twitch = twitch; 
		if(tiktok!==null) myRegistration.profile.socialMedia.tiktok = tiktok; 
		if(snapchat!==null) myRegistration.profile.socialMedia.snapchat = snapchat; 
		if(bio) myRegistration.profile.bio = bio; 
		if(sceneName) myRegistration.profile.sceneName = sceneName; 
		myRegistration.profile.preferences.showHometown = showHometown; 
		myRegistration.profile.preferences.showAge = showAge; 
		myRegistration.profile.preferences.allowEmail = allowMessages; 
		if(!myRegistration.profile.hometown) myRegistration.profile.hometown = {}; 
		myRegistration.profile.hometown.city = hometownCity; 
		myRegistration.profile.hometown.state = hometownState; 
		myRegistration.profile.hometown.zip = hometownZip; 
		myRegistration.guest.role = role; 
		myRegistration.guest.pronouns = pronouns; 
		
		setIsSavingToServer(true); 
		setMyRegistration(myRegistration); 
	} 
  useEffect(() => {
		if(myRegistration.profile)
			setInstagram((myRegistration.profile.website==null)?'':myRegistration.profile.website); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setInstagram((myRegistration.profile.socialMedia.instagram==null)?'':myRegistration.profile.socialMedia.instagram); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setTwitter((myRegistration.profile.socialMedia.twitter==null)?'':myRegistration.profile.socialMedia.twitter); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setFacebook((myRegistration.profile.socialMedia.facebook==null)?'':myRegistration.profile.socialMedia.facebook); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setTelegram((myRegistration.profile.socialMedia.telegram==null)?'':myRegistration.profile.socialMedia.telegram); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setTwitch((myRegistration.profile.socialMedia.twitch==null)?'':myRegistration.profile.socialMedia.twitch); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setTiktok((myRegistration.profile.socialMedia.tiktok==null)?'':myRegistration.profile.socialMedia.tiktok); 
		if(myRegistration.profile && myRegistration.profile.socialMedia)
			setSnapchat((myRegistration.profile.socialMedia.snapchat==null)?'':myRegistration.profile.socialMedia.snapchat);
		if(myRegistration.profile)
			setBio((myRegistration.profile.bio==null)?'':myRegistration.profile.bio);
		if(myRegistration.guest && myRegistration.guest.sceneName)
			setSceneName((myRegistration.guest.sceneName==null)?'':myRegistration.guest.sceneName);
		if(myRegistration.profile && myRegistration.profile.hometown)
			setHometownCity((myRegistration.profile.hometown.city==null)?'':myRegistration.profile.hometown.city);
		if(myRegistration.profile && myRegistration.profile.hometown)
			setHometownState((myRegistration.profile.hometown.state==null)?'':myRegistration.profile.hometown.state);
		if(myRegistration.profile && myRegistration.profile.hometown)
			setHometownZip((myRegistration.profile.hometown.zip==null)?'':myRegistration.profile.hometown.zip);
		if(myRegistration.profile && myRegistration.profile.preferences)
			setShowHometown(myRegistration.profile.preferences.showHometown);
		if(myRegistration.profile && myRegistration.profile.preferences)
			setShowAge(myRegistration.profile.preferences.showAge);
		if(myRegistration.profile && myRegistration.profile.preferences)
			setAllowMessages(myRegistration.profile.preferences.allowEmail);
		if(myRegistration.guest)
			setPronouns((myRegistration.guest.pronouns==null)?'':myRegistration.guest.pronouns);
		if(myRegistration.guest)
			setRole((myRegistration.guest.role==null)?'':myRegistration.guest.role);
		if(myRegistration.status==='ok'){ setIsSavingToServer(false); setIsEdited(false); }
  }, [myRegistration]) 
			 
    return (<>
			<Container className="form-cards">
				<Row>
					<Col md={12} lg={6}>
						<FormCard id="" className="red">
							<Card.Header as="h5">All About Me</Card.Header>
							<Card.Body>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Scene Name</FormLabel.Primary>
										<Form.Control placeholder="" id="sceneName" value={sceneName} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
										<Form.Text id="scenenameHelpBlock" muted>This appears on your profile and event badge.</Form.Text>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Primary>Role Identity</FormLabel.Primary>
										<Form.Control id="profile_role" as="select" value={role} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(roleOptions).map((value) => <option key={value} value={value}>{roleOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col}  sm={6}>
										<FormLabel.Primary>Pronouns</FormLabel.Primary>
										<Form.Control id="profile_pronouns" as="select" value={pronouns} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
												<option></option>
												{
													Object.keys(pronounsOptions).map((value) => <option key={value} value={value}>{pronounsOptions[value]}</option> )
												}
										</Form.Control>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<FormLabel.Primary as={Col} sm={12}>Hometown</FormLabel.Primary>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col}  sm={5}>
										<FormLabel.Secondary>City</FormLabel.Secondary>
										<Form.Control id="profile_city" value={hometownCity} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
									<Form.Group as={Col}  sm={3}>
										<FormLabel.Secondary>State/Prov</FormLabel.Secondary>
										<Form.Control id="profile_state" as="select" value={hometownState} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} >
											<option></option>
											{
												Object.keys(hometownStateOptions).map((value) => <option key={value}>{value}</option> )
											}
										</Form.Control>
									</Form.Group>
									<Form.Group as={Col} sm={4}>
										<FormLabel.Secondary>Zip/Postcode</FormLabel.Secondary>
										<Form.Control id="profile_zip" value={hometownZip} onChange={e => { handleFieldChange(e.target.id, e.target.value); }}/>
									</Form.Group>
								</Form.Row>
								<Form.Row>
									<Form.Group as={Col} sm={12}>
										<FormLabel.Primary>Website</FormLabel.Primary>
										<StyledInputGroup>
											<InputGroup.Prepend>
												<InputGroup.Text id="basic-addon1">https://</InputGroup.Text>
											</InputGroup.Prepend>
											<Form.Control id="profile_website" className="socialMediaEntryField" value={website} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="" />
										</StyledInputGroup>
									</Form.Group>
								</Form.Row>
							</Card.Body>
						</FormCard>

						<FormCard id="ProfileEditBio" className="blue">
							<Card.Header as="h5">My Bio</Card.Header>
							<Editor
								apiKey='fn70u8o0lfx4eb27syw6okc0qo0zlg2sfmze2m0b99x16kvs'
								value={bio}
								init={{
									 height: 200,
									 menubar: false,
									plugins: 'emoticons',
									 toolbar:
										 'bold italic strikethrough | forecolor backcolor emoticons | bullist numlist | removeformat '
								 }}
								 onEditorChange={handleEditorChange}
							 />
						</FormCard>
						<FormCard id="ProfilePreferences" className="gold">
							<Card.Header as="h5">Preferences</Card.Header>
							<Card.Body>
								<Container>
									<Form.Group as={Row} className="preferenceCheckbox">
										<Switch isOn={showHometown} onColor="#0d9948" handleToggle={() => {setShowHometown(!showHometown); setIsEdited(true);  }} id="profile_showhometown" />
										<Form.Label htmlFor="profile_showhometown">Show Hometown on my Profile</Form.Label>
									</Form.Group>
									<Form.Group as={Row} className="preferenceCheckbox">
										<Switch isOn={showAge} onColor="#0d9948" handleToggle={() => {setShowAge(!showAge); setIsEdited(true);  }} id="profile_showage" />
										<Form.Label htmlFor="profile_showage">Show My Age on my Profile</Form.Label>
									</Form.Group>
									<Form.Group as={Row} className="preferenceCheckbox">
										<Switch isOn={allowMessages} onColor="#0d9948" handleToggle={() => {setAllowMessages(!allowMessages); setIsEdited(true);  }} id="profile_allowmessages" />
										<Form.Label htmlFor="profile_allowmessages">
											Allow Other Guests to Send Me Messages
											<Form.Text id="allowmessagesHelpBlock" muted>You'll get email, but your email address is kept private.</Form.Text>
										</Form.Label>
									</Form.Group>
								</Container>
							</Card.Body>
						</FormCard>
					</Col>


					<Col md={12} lg={6}>
						
						<FormCard id="ProfilePhotos" className="green">
							<Card.Header as="h5">My Photos</Card.Header>
								<Card.Body>
								<ProfilePhotosManager />
								</Card.Body>
						</FormCard>
						
						<FormCard className="purple">
							<Card.Header as="h5">My Social Media Accounts</Card.Header>
							<Card.Body>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon1"><FontAwesomeIcon icon={['fab', 'instagram']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_instagram" className="socialMediaEntryField" value={instagram} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Instagram Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon2"><FontAwesomeIcon icon={['fab', 'twitter']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_twitter" className="socialMediaEntryField" value={twitter} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Twitter Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon3"><FontAwesomeIcon icon={['fab', 'facebook']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_facebook" className="socialMediaEntryField" value={facebook} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Facebook Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon4"><FontAwesomeIcon icon={['fab', 'telegram-plane']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_telegram"className="socialMediaEntryField" value={telegram} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Telegram Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon5"><FontAwesomeIcon icon={['fab', 'tiktok']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_tiktok" className="socialMediaEntryField" value={tiktok} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="TikTok Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon6"><FontAwesomeIcon icon={['fab', 'twitch']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_twitch" className="socialMediaEntryField" value={twitch} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Twitch Username" />
								</StyledInputGroup>
								<StyledInputGroup>
									<InputGroup.Prepend>
										<IconInputLabel id="basic-addon7"><FontAwesomeIcon icon={['fab', 'snapchat-ghost']} /></IconInputLabel>
									</InputGroup.Prepend>
									<Form.Control id="social_snapchat" className="socialMediaEntryField" value={snapchat} onChange={e => { handleFieldChange(e.target.id, e.target.value); }} placeholder="Snapchat Username" />
								</StyledInputGroup>
							</Card.Body>
						</FormCard>
						
					</Col>
				</Row>


				<Row>
					<Col>
						{isSavingToServer ?  
						 <SaveSpinnerContainer>
							<Spinner animation="grow" variant="primary" />&nbsp;
							<Spinner animation="grow" variant="secondary" />&nbsp;
							<Spinner animation="grow" variant="success" />&nbsp;
							<Spinner animation="grow" variant="danger" />&nbsp;
							<Spinner animation="grow" variant="warning" />&nbsp;
							<Spinner animation="grow" variant="info" />
						 </SaveSpinnerContainer>
							:
							<SaveButton disabled={ (isEdited)?false:true } onClick={e => { savePublicProfile(); }}  />
 						}
					</Col>
				</Row>
			</Container>
</>
    );
}
 
export default PublicInfoEditor;